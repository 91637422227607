import PropTypes from "prop-types";

export const TableHeaderComponent = ({ columnNames}) => {
  return (
    <tr>
      {columnNames.map((columnName) => (
        <th scope="col" key={columnName}>
          {columnName}
        </th>
      ))}
    </tr>
  );
};

TableHeaderComponent.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired
};
