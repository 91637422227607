import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTransferService from "../core/service/dataTransferService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import DefaultProfile from "../assets/images/defaultProfile.png";

const Profile = () => {
  const dataTransferService = new DataTransferService();
  const navigate = useNavigate();

  const handleLogout = () => {
    dataTransferService.logout();
    navigate("/login");
  };

  const userProfileUrl = dataTransferService.getUserProfile()
    ? `${dataTransferService.getBloburl()}${dataTransferService.getUserProfile()}`
    : DefaultProfile;

  return (
    <div>
      <Dropdown align="end">
        <Dropdown.Toggle
          as="img"
          src={userProfileUrl}
          alt="User profile"
          className="rounded-circle pointer"
          id="dropdown-custom-components"
          width={42}
          height={42}
          style={{
            objectFit: "cover",
          }}
        />

        <Dropdown.Menu>
          <Dropdown.Item
            as={Link}
            to="/settings"
            className="fs-14 d-flex align-items-center"
          >
            <FontAwesomeIcon icon={faGear} className="me-2" />
            <p className="mb-0">Settings</p>
          </Dropdown.Item>

          <Dropdown.Item
            onClick={handleLogout}
            className="fs-14 d-flex align-items-center"
          >
            <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
            <p className="mb-0">Logout</p>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Profile;
