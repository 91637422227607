import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DefaultProfile from "../../assets/images/defaultProfile.png";
import { DisabledDropdownComponent } from "../../common/dropDown";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import DataTransferService from "../../core/service/dataTransferService";
import UserService from "../../core/service/userService";

const InviteUsers = () => {
  const dataTransferService = new DataTransferService();
  const [organisationName, setOrganisationName] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const userService = new UserService();
  const userProfileUrl = dataTransferService.getUserProfile()
    ? `${dataTransferService.getBloburl()}${dataTransferService.getUserProfile()}`
    : DefaultProfile;
    
  const fetchUsers = (organisationName) => {
    const userService = new UserService();
    setLoading(true);
    userService
      .fetchUsers(organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setUsers(response.data.responseBody);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("admin-fetch-users", error.response);
      });
  };

  useEffect(() => {
    const storedOrganisation =
      dataTransferService.getOrganisationDTO("organisationDTO");
    if (storedOrganisation) {
      setOrganisationName(storedOrganisation.organisationName);
      fetchUsers(storedOrganisation.organisationName);
    } else {
      fetchUsers(dataTransferService.getSelectedOrganization());
      setLoading(false);
    }
  }, []);

  const handleInviteUser = () => {
    navigate("/invite-form");
  };

  const handleProceed = () => {
    if (
      dataTransferService.getOrganisationDTO() &&
      !dataTransferService.getOrgAndBranches()
    ) {
      navigate("/review-organisation");
    } else {
      fetchOrgAndBran();
    }
  };

  const fetchOrgAndBran = () => {
    setLoading(true);
    userService
      .fetchOrgAndBranName()
      .then((response) => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          if (response.data.responseBody.organisations.length > 0) {
            dataTransferService.removeOrganisationDTO();
            dataTransferService.setOrgAndBranches(
              response.data.responseBody.organisations
            );
            navigate("/settings");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("admin-fetch-org", error.response);
      });
  };

  return (
    <div className="d-flex align-items-center justify-content-center full-height p-4">
      <div className="view-branches-container p-4">
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-22 fw-600">Hi {dataTransferService.getUserName()}</p>
          <img
            src={userProfileUrl}
            alt="user-profile-icon"
            className="rounded-circle me-3"
            width={42}
            height={42}
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <hr />

        <div className="mt-5 d-flex align-items-center justify-content-start">
          <div className="d-flex flex-column">
            <p className="fs-4 fw-600">User Management</p>
            <p className="fs-14 fw-500 mt-4">
              Select Organization <span className="color-star">*</span>
            </p>
            <DisabledDropdownComponent
              toggleClassName="fs-14 mt-2 fw-semibold disabled-dropdown custom-arrow"
              selected={
                organisationName ||
                dataTransferService.getSelectedOrganization()
              }
            />
            <button
              type="button"
              className="btn mt-4 btn-outline custom-btn-outline w-50"
              onClick={handleInviteUser}
            >
              + Invite User
            </button>
          </div>
        </div>

        <div className="mt-4 fs-6 fw-500">
          <div className="d-flex align-items-center justify-content-between">
            <span style={{ flex: 2 }}>Users</span>
            <span style={{ flex: 1 }}>Permission Type</span>
            <span style={{ flex: 1 }}>Location Access</span>
            <span style={{ flex: 1 }}>Action</span>
          </div>
          <hr />

          {loading ? (
            <Loader loading={loading} />
          ) : (
            users.map((user, index) => (
              <React.Fragment key={index}>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center"
                    style={{ flex: 2 }}
                  >
                    <img
                      src={
                        dataTransferService.getOrgProfileImage()
                          ? dataTransferService.getBloburl() +
                            dataTransferService.getOrgProfileImage()
                          : dataTransferService.getBloburl() +
                            dataTransferService.getOrganisationProfile()
                      }
                      alt="user-profile-icon"
                      className="rounded-circle me-3"
                      width={42}
                      height={42}
                      style={{
                        objectFit: "cover",
                      }}
                    />
                    <div className="d-flex flex-column">
                      <span>{user.userName}</span>
                      <span>{user.userEmail}</span>
                    </div>
                  </div>
                  <span style={{ flex: 1 }}>{user.userRole}</span>
                  <span style={{ flex: 1 }}>
                    {user.accessibleBranchesCount} locations
                  </span>
                  <div
                    style={{ flex: 1 }}
                    className="d-flex justify-content-start"
                  >
                    <span className="me-3 pointer">
                      <FontAwesomeIcon icon={faEdit} className="me-1" /> Edit
                    </span>
                    <span className="pointer">
                      <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete
                    </span>
                  </div>
                </div>
                <hr />
              </React.Fragment>
            ))
          )}
        </div>

        <div className="mt-5 d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-success w-25"
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteUsers;
