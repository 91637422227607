import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Flex from "../../common/flex";
import { Link } from "react-router-dom";
import { DropdownComponent } from "../../common/dropDown";
import Profile from "../../common/profile";
import ReturnTrackerRequest from "../../core/service/returnTrackerRequest";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../../core/service/dataTransferService";
import { PaginationComponent } from "../../common/pagination";
import Loader from "../../core/loader/loader";
import SearchInput from "../../common/search";
import { Back } from "../../common/back";
import SubtleBadge, {
  statusByColor,
  toPascalWord,
} from "../../common/subtleBadge";

const ViewAllReturnRequests = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Newest");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);

  const returnTrackerRequest = new ReturnTrackerRequest();
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();

  const fetchReturnRequests = (serialNumber, page, size) => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .searchReturnsBySerialNumber(
        organisationName,
        branchName,
        serialNumber,
        page,
        size
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchReturns", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const fetchSortedReturns = (order, page, size) => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .sortReturnRequests(order, organisationName, branchName, page, size)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchSortedReturns", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      fetchReturnRequests(searchTerm, pageIndex, pageSize);
    } else {
      fetchSortedReturns(selectedOption, pageIndex, pageSize);
    }
  }, [searchTerm, pageIndex, selectedOption]);

  const handleChangeOptions = (option) => {
    setSelectedOption(option);
    setPageIndex(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };
  
  const formatNumber = (num) =>
    num >= 1_000_000 ? (num / 1_000_000).toFixed(1) + "M" : 
    num >= 1_000 ? (num / 1_000).toFixed(1) + "K" : num;

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="card-all-return-requests p-4 mt-5">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="padding-heading fw-600 ps-2">All Return Requests</p>
        </Flex>

        <Flex className="mt-4 mb-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
          />

          {!searchTerm && (
            <DropdownComponent
              value={selectedOption}
              selected={
                <Flex className="d-flex align-items-center">
                  <span className="fs-12 fw-400 grayish-blue pe-2">
                    Sort by:
                  </span>
                  {selectedOption}
                </Flex>
              }
              options={selectOptions}
              onSelect={handleChangeOptions}
              toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
            />
          )}
        </Flex>

        <Table>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Company</th>
              <th>Model Name</th>
              <th>Customer name</th>
              <th>Created</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              returnRequests.map((request) => (
                <tr key={request.serialNumber}>
                  <td>{request.serialNumber}</td>
                  <td>{request.company}</td>
                  <td className="limited-text">{request.modelName}</td>
                  <td>{request.customerName}</td>
                  <td>{formatDate(request.created)}</td>
                  <td>
                    <SubtleBadge
                      bg={statusByColor(request.returnStatus)}
                      className="fs-14 fw-500"
                    >
                      {toPascalWord(request.returnStatus)}
                    </SubtleBadge>
                  </td>
                  <td>
                    <Link
                      to={`/edit-return-tracker/${request.serialNumber}`}
                      style={{
                        color: "#127BFF",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
          <span>
            {totalItems > 0
              ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                  (pageIndex + 1) * pageSize,
                  totalItems
                )} of ${formatNumber(totalItems)} entries`
              : "No entries available"}
          </span>

          <PaginationComponent
            prevPage={prevPage}
            nextPage={nextPage}
            isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
            isPrevDisabled={pageIndex === 0}
            pageIndex={pageIndex}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </Flex>
      </div>
    </div>
  );
};

export default ViewAllReturnRequests;
