import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const SearchInput = ({
  value,
  onChange,
  clearSearch,
  placeholder,
  isFocused,
  setIsFocused,
  className,
}) => {
  return (
    <div
      className={`input-group position-relative ${className || "w-100"} ${!value ? "pe-5" : ""}`}
    >
      <input
        type="text"
        className={`form-control search ${!value && !isFocused ? "ps-5" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{ paddingRight: "2.5rem" }}
      />
      {!value && (
        <span className="position-absolute top-50 start-0 translate-middle-y ps-3">
          <FontAwesomeIcon icon={faSearch} className="icon-color" />
        </span>
      )}
      {value && (
        <span
          className="position-absolute top-50 end-0 translate-middle-y pointer"
          onClick={clearSearch}
          style={{
            cursor: "pointer",
            zIndex: 10,
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired, 
  onChange: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired, 
  placeholder: PropTypes.string, 
  isFocused: PropTypes.bool.isRequired,
  setIsFocused: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SearchInput;
