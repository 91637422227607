import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { wheelerBillingValidations } from "../../validations/forms";
import DataTransferService from "../../core/service/dataTransferService";
import Profile from "../../common/profile";
import Flex from "../../common/flex";
import InvoiceService from "../../core/service/invoiceService";
import { toast } from "react-toastify";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { useNavigate } from "react-router-dom";
import Loader from "../../core/loader/loader";
import {
  InputField,
  SelectField,
  ServiceField,
} from "../../validations/inputFields";
import { Back } from "../../common/back";

const WheelerBilling = () => {
  const initialValues = {
    services: {
      wheelBalancing: {
        selected: false,
        wheelBalancingQuantity: "",
        wheelBalancingPrice: "",
      },
      weightBalancing: {
        selected: false,
        weightBalancingQuantity: "",
        weightBalancingPrice: "",
      },
      tyrePuncture: {
        selected: false,
        tyrePunctureQuantity: "",
        tyrePuncturePrice: "",
      },
      others: { selected: false, othersQuantity: "", othersPrice: "" },
    },
    customerName: "",
    customerMobileNumber: "",
    driverMobileNumber: "",
    vehicleNumber: "",
    vehicleManufacturer: "",
    customVehicleManufacturer: "",
    vehicleModel: "",
    customVehicleModel: "",
    vehicleOdometerReading: "",
    vehicleAverageKmsPerDay: "",
    useGstInvoice: false,
    gstinNumber: "",
    businessName: "",
    createdOn: "",
  };

  const dataTransferService = new DataTransferService();
  const invoiceService = new InvoiceService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async (values) => {
    const selectedServices = [
      values.services.wheelBalancing.selected,
      values.services.weightBalancing.selected,
      values.services.tyrePuncture.selected,
      values.services.others.selected,
    ];
    const isAnyServiceSelected = selectedServices.some((service) => service);

    if (!isAnyServiceSelected) {
      toast.error("Please select at least one service.", {
        theme: "colored",
      });
      return;
    }
    const formattedData = {
      customerDTO: {
        customerName: values.customerName,
        customerMobileNumber: values.customerMobileNumber,
      },
      ...((values.businessName || values.gstinNumber) && {
        businessDetailsDTO: {
          businessName: values.businessName,
          gstinNumber: values.gstinNumber,
        },
      }),
      vehicleDTO: {
        vehicleNumber: values.vehicleNumber,
        vehicleManufacturer: values.vehicleManufacturer.toUpperCase(),

        customVehicleManufacturer:
          values.vehicleManufacturer === "Other"
            ? values.customVehicleManufacturer.toUpperCase()
            : values.vehicleManufacturer.toUpperCase(),
        vehicleModel: values.vehicleModel.toUpperCase(),
        customVehicleModel:
          values.vehicleModel === "Other"
            ? values.customVehicleModel.toUpperCase()
            : values.vehicleModel.toUpperCase(),

        vehicleOdometerReading: values.vehicleOdometerReading,
        vehicleAverageKmsPerDay: values.vehicleAverageKmsPerDay,
        driverMobileNumber: values.driverMobileNumber,
        vehicleType: "CAR",
      },
      servicesDTO: {
        wheelBalancing: values.services.wheelBalancing.selected,
        weightBalancing: values.services.weightBalancing.selected,
        tyrePuncture: values.services.tyrePuncture.selected,
        others: values.services.others.selected,
        wheelBalancingQuantity:
          values.services.wheelBalancing.wheelBalancingQuantity || 0,
        wheelBalancingPrice:
          values.services.wheelBalancing.wheelBalancingPrice || 0,
        weightBalancingQuantity:
          values.services.weightBalancing.weightBalancingQuantity || 0,
        weightBalancingPrice:
          values.services.weightBalancing.weightBalancingPrice || 0,
        tyrePunctureQuantity:
          values.services.tyrePuncture.tyrePunctureQuantity || 0,
        tyrePuncturePrice: values.services.tyrePuncture.tyrePuncturePrice || 0,
        othersQuantity: values.services.others.othersQuantity || 0,
        othersPrice: values.services.others.othersPrice || 0,
      },
    };
    submitForm(formattedData);
  };

  const submitForm = (formattedData) => {
    setLoading(true);
    invoiceService
      .generateCarInvoice(
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        formattedData
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          navigate(`/invoice/${response.data.responseBody}`);
          toast.success("Invoice Generated Successfully", {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("generate-invoice", error.response);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <div className="box-container p-4 d-flex justify-content-start flex-direction-column">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-4 fw-600">Bill New Service</p>
        </div>

        <hr className="mt-3" />

        <Formik
          initialValues={initialValues}
          validationSchema={wheelerBillingValidations}
          onSubmit={handleSubmit}
          validateOnChange={true}
        >
          {(formik) => (
            <Form>
              <div className="row pt-3 ">
                <div className="col">
                  <InputField
                    label="Customer Name"
                    name="customerName"
                    type="text"
                    placeholder="Enter Customer Name"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Customer Mobile Number"
                    name="customerMobileNumber"
                    type="text"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row pt-3 ">
                <div className="col">
                  <InputField
                    label="Vehicle Number"
                    name="vehicleNumber"
                    type="text"
                    placeholder="Enter Vehicle Number"
                    className="form-control w-75"
                  />
                </div>

                <div className=" col">
                  <SelectField
                    label="Vehicle Manufacturer"
                    name="vehicleManufacturer"
                    options={[
                      "Toyota",
                      "Honda",
                      "Ford",
                      "BMW",
                      "AUDI",
                      "Other",
                    ]}
                    className="form-control w-75"
                  />
                  {formik.values.vehicleManufacturer === "Other" && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Vehicle Manufacturer"
                        name="customVehicleManufacturer"
                        type="text"
                        placeholder="Enter Vehicle Manufacturer"
                        className="form-control w-75"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col">
                  <SelectField
                    label="Vehicle Model"
                    name="vehicleModel"
                    options={["Corolla", "Civic", "Mustang", "Other"]}
                    className="form-control w-75"
                  />

                  {formik.values.vehicleModel === "Other" && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Vehicle Model"
                        name="customVehicleModel"
                        type="text"
                        placeholder="Vehicle Model"
                        className="form-control w-75 "
                      />
                    </div>
                  )}
                </div>

                <div className="col">
                  <InputField
                    label="Vehicle Odometer Reading"
                    name="vehicleOdometerReading"
                    type="number"
                    placeholder="Enter Vehicle Odometer Reading"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Vehicle Average KM's Per Day"
                    name="vehicleAverageKmsPerDay"
                    type="number"
                    placeholder="Enter Vehicle Average KM's Per Day"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <label className="fs-14 fw-500" htmlFor="createdOn">
                    Created On
                  </label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control w-75"
                    value={currentDate}
                    disabled
                    id="createdOn"
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <InputField
                    label="Driver Mobile Number"
                    name="driverMobileNumber"
                    type="text"
                    placeholder="Enter Driver Mobile Number"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="mt-3 fs-14 fw-500 pb-1">
                    Select the services<span className="color-star">*</span>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2"
                      style={{ color: "#cfcfcf" }}
                    />
                  </p>
                  <p className="muted-grayish-blue fs-14 fw-400">
                    Click all the services performed
                  </p>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="wheelBalancing"
                      serviceTitle="Wheel Alignment"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="weightBalancing"
                      serviceTitle="Weight Balancing"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyrePuncture"
                      serviceTitle="Tyre Puncture"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField serviceName="others" serviceTitle="Other" />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col d-flex align-items-center">
                  <Field
                    type="checkbox"
                    name="useGstInvoice"
                    className="form-check-input me-2"
                    id="useGstInvoice"
                  />
                  <label
                    className="form-check-label mb-0 fs-14 fw-500"
                    htmlFor="useGstInvoice"
                  >
                    Use GST Invoice
                  </label>
                </div>
              </div>

              {formik.values.useGstInvoice && (
                <div className="row mt-3">
                  <div className="col">
                    <InputField
                      label="GSTIN Number"
                      name="gstinNumber"
                      type="text"
                      placeholder="Enter GSTIN Number"
                      className="form-control w-75"
                      id="gstinNumber"
                    />
                  </div>
                  <div className="col">
                    <InputField
                      label="Business Name"
                      name="businessName"
                      type="text"
                      placeholder="Enter Business Name"
                      className="form-control w-75"
                      id="businessName"
                    />
                  </div>
                </div>
              )}

              <div className="col mt-3">
                <button type="submit" className="button-indigo">
                  Generate Invoice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WheelerBilling;
