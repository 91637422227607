import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faBell } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Flex from "./flex";
import PropTypes from "prop-types";

const AlertAnimation = ({ lastServiceDate, upcomingAlertDate }) => {
  const lastService = new Date(lastServiceDate);
  const upcomingAlert = new Date(upcomingAlertDate);
  const currentDate = new Date();
  const isValidLastService = !isNaN(lastService.getTime());
  const isValidUpcomingAlert = !isNaN(upcomingAlert.getTime());
  const totalDuration =
    isValidUpcomingAlert && isValidLastService
      ? upcomingAlert - lastService
      : 1;
  const timePassed = isValidLastService ? currentDate - lastService : 0;
  const percentage =
    totalDuration > 0
      ? Math.max(0, Math.min(100, (timePassed / totalDuration) * 100))
      : 0;

  const customLineStyle = {
    position: "relative",
    width: "12.313rem",
    height: "0.225rem",
    backgroundColor: "#D9D9D9",
    borderRadius: "0.25rem",
    transition: "opacity 0.5s ease",
  };

  const afterStyle = {
    content: '""',
    position: "absolute",
    height: "100%",
    backgroundColor: "#3BD0FF",
    opacity: 1,
    width: "0",
    transition: "width 0.5s ease",
  };

  return (
    <Flex className="align-items-center justify-content-center pt-3">
      <Flex className="align-items-center justify-content-between">
        <div className="text-center me-5">
          <FontAwesomeIcon icon={faClockRotateLeft} />
          <div className="fs-14 fw-600">Last Service</div>
          <div className="fs-14 fw-normal">
            {isValidLastService ? lastService.toLocaleDateString() : "N/A"}
          </div>
        </div>

        <div
          className="custom-line"
          style={customLineStyle}
          data-percentage={percentage.toString()}
        >
          <div
            style={{
              ...afterStyle,
              width: `${percentage}%`,
            }}
          />
        </div>

        <div className="text-center ms-5">
          <FontAwesomeIcon icon={faBell} />
          <div className="fs-14 fw-600">Upcoming Alert</div>
          <div className="fs-14 fw-normal">
            {isValidUpcomingAlert ? upcomingAlert.toLocaleDateString() : "N/A"}
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

AlertAnimation.propTypes = {
  lastServiceDate: PropTypes.string.isRequired,
  upcomingAlertDate: PropTypes.string.isRequired,
};
export default AlertAnimation;
