import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Profile from "../../common/profile";
import { newReturnTrackerValidations } from "../../validations/forms";
import ReturnTrackerService from "../../core/service/returnTrackerService";
import DataTransferService from "../../core/service/dataTransferService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";
import { Back } from "../../common/back";
import Flex from "../../common/flex";
import { InputField } from "../../validations/inputFields";

const NewReturnTracker = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const returnTrackerService = new ReturnTrackerService();
  const dataTransferService = new DataTransferService();

  const navigate = useNavigate();

  const initialValues = {
    serialNumber: "",
    manufacturerReferenceId: "",
    tyreManufacturer: "",
    customTyreManufacturer: "",
    tyreModel: "",
    customTyreModel: "",
    customerName: "",
    customerMobileNumber: "",
    returnStatus: "",
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async (values) => {
    const formattedData = {
      serialNumber: values.serialNumber,
      manufacturerReferenceId: values.manufacturerReferenceId,
      tyreManufacturer: values.tyreManufacturer.toUpperCase(),
      customTyreManufacturer:
        values.tyreManufacturer === "OTHER"
          ? values.customTyreManufacturer.toUpperCase()
          : "",
      tyreModel: values.tyreModel.toUpperCase(),
      customTyreModel:
        values.tyreModel === "OTHER"
          ? values.customTyreModel.toUpperCase()
          : "",

      returnStatus: values.returnStatus,
      customerDTO: {
        customerName: values.customerName,
        customerMobileNumber: values.customerMobileNumber,
      },
    };
    submitForm(formattedData);
  };

  const submitForm = (formattedData) => {
    setLoading(true);
    const branchName = dataTransferService.getSelectedBranch();
    const organisationName = dataTransferService.getSelectedOrganization();
    returnTrackerService
      .createReturnTracker(formattedData, branchName, organisationName)
      .then((response) => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          navigate("/return-tracker");
          toast.success(`${response.data.responseMessage}`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("submitForm", error.response);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>
      <div className="container new-return-tracker-container p-4">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="padding-heading fw-600 ps-2">
            Create New Return Tracker
          </p>
        </Flex>

        <div>
          <hr className="pt-3 hr-color"></hr>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={newReturnTrackerValidations}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <div className="row pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">
                    Serial Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="serialNumber"
                    placeholder="Enter Tyre Serial Number"
                    className="form-control narrow-input placeholder-text"
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-600">
                    Manufacturer Reference Id
                    <span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="manufacturerReferenceId"
                    placeholder="Enter Reference Tracker Id"
                    className="form-control narrow-input"
                  />
                  <ErrorMessage
                    name="manufacturerReferenceId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">
                    Tyre Manufacturer<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="tyreManufacturer"
                    className="form-select narrow-input placeholder-text"
                  >
                    <option value="" disabled>Select Manufacturer</option>
                    <option value="MICHELIN">MICHELIN</option>
                    <option value="BRIDGESTONE">BRIDGESTONE</option>
                    <option value="GOODYEAR">GOODYEAR</option>
                    <option value="OTHER">OTHER</option>
                  </Field>
                  {formik.values.tyreManufacturer === "OTHER" && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Tyre Manufacturer"
                        name="customTyreManufacturer"
                        type="text"
                        placeholder="Enter Tyre Manufacturer"
                        className="form-control w-75"
                      />
                    </div>
                  )}
                  <ErrorMessage
                    name="tyreManufacturer"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col">
                  <label className="label-text fw-500">
                    Tyre Model<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="tyreModel"
                    className="form-select narrow-input placeholder-text"
                  >
                    <option value="" disabled>Select Model</option>
                    <option value="MICHELIN_PILOT_SPORT">
                      MICHELIN_PILOT_SPORT
                    </option>
                    <option value="MICHELIN_PRIMACY_4">
                      MICHELIN_PRIMACY_4
                    </option>
                    <option value="MICHELIN_DEFENDER_T_H">
                      MICHELIN_DEFENDER_T_H
                    </option>
                    <option value="BRIDGESTONE_TURANZA_T005">
                      BRIDGESTONE_TURANZA_T005
                    </option>
                    <option value="BRIDGESTONE_DUELER_HL">
                      BRIDGESTONE_DUELER_HL
                    </option>
                    <option value="GOODYEAR_ASSURANCE_WEATHERREADY">
                      GOODYEAR_ASSURANCE_WEATHERREADY
                    </option>
                    <option value="OTHER">OTHER</option>
                  </Field>
                  {formik.values.tyreModel === "OTHER" && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Tyre Model"
                        name="customTyreModel"
                        type="text"
                        placeholder="Enter Tyre Model"
                        className="form-control w-75"
                      />
                    </div>
                  )}
                  <ErrorMessage
                    name="tyreModel"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">
                    Customer Name<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerName"
                    placeholder="Enter Customer Name"
                    className="form-control narrow-input placeholder-text"
                  />
                  <ErrorMessage
                    name="customerName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-500">
                    Customer Mobile Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerMobileNumber"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control narrow-input placeholder-text"
                  />
                  <ErrorMessage
                    name="customerMobileNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">Created On</label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control narrow-input placeholder-text"
                    value={currentDate}
                    disabled
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-500">
                    Return Status<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="returnStatus"
                    className="form-select narrow-input placeholder-text"
                  >
                    <option value="">Select Status</option>
                    <option value="IN_PROCESS">IN_PROCESS</option>
                    <option value="PASSED_QC">PASSED_QC</option>
                    <option value="FAILED_QC">FAILED_QC</option>
                  </Field>
                  <ErrorMessage
                    name="returnStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="mt-4 mb-3 d-flex justify-content-end">
                <button type="submit" className="button-indigo">
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default NewReturnTracker;
