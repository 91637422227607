import { Appconfig } from "../config/appConfig";
import axiosInstance from "../tokenInterceptor/axiosInstance";

export class UserService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + "user";
  }

  login(formData) {
    const body = JSON.stringify(formData);
    return axiosInstance.post(this.Api + "/login", body, {});
  }

  fetchBranches(organisationName) {
    return axiosInstance.get(`${this.Api}/branches-by`, {
      params: { organisationName },
    });
  }

  fetchUsers(organisationName) {
    return axiosInstance.get(`${this.Api}/admin-fetch-users`, {
      params: { organisationName },
    });
  }

  inviteUser(data) {
    const { organisationName, userRole, ...bodyData } = data;
    return axiosInstance.post(`${this.Api}/admin-invite`, bodyData, {
      params: {
        organisationName,
        userRole,
      },
    });
  }

  createOrganisation(data) {
    return axiosInstance.post(
      `${this.Api}/admin-create-organisation`,
      data,
      {}
    );
  }

  createBranch(data) {
    return axiosInstance.post(`${this.Api}/create-branches`, data, {});
  }

  fetchOrgAndBranName() {
    return axiosInstance.get(this.Api + "/admin-fetch-org");
  }

  setPassword(body, userEmail) {
    return axiosInstance.post(this.Api + "/set-password", body, {
      params: {
        userEmail,
      },
    });
  }

  sendRemindNow(mobileNumber) {
    return axiosInstance.post(
      this.Api + "/send-remind-now",
      {},
      {
        params: { mobileNumber },
      }
    );
  }

  forgotPassword(userEmail) {
    return axiosInstance.post(
      this.Api + "/forgot-password",
      {},
      {
        params: { userEmail },
      }
    );
  }

  verifyOtp(userEmail, otp) {
    return axiosInstance.post(
      this.Api + "/verify-otp",
      {},
      {
        params: { userEmail, otp },
      }
    );
  }

  sendBulkAlertsBasedOnVehicle(
    organisationName,
    branchName,
    vehicleType,
    vehicleModel,
    vehicleManufacturer
  ) {
    const params = new URLSearchParams();
    params.append("organisationName", organisationName);
    params.append("branchName", branchName);
    params.append("vehicleType", vehicleType);
    params.append("vehicleModel", vehicleModel);
    params.append("vehicleManufacturer", vehicleManufacturer);
    return axiosInstance.post(
      `${this.Api}/send-bulk-alerts?${params.toString()}`
    );
  }

  fetchCountToSendBulkAlerts(
    organisationName,
    branchName,
    vehicleType,
    vehicleModel,
    vehicleManufacturer
  ) {
    return axiosInstance.get(`${this.Api}/bulk-count`, {
      params: {
        organisationName,
        branchName,
        vehicleType,
        vehicleModel,
        vehicleManufacturer,
      },
    });
  }

  allBranchesReport = (organisationName, startDate, endDate) => {
    return axiosInstance.get(`${this.Api}/branch-transactions`, {
      params: { organisationName, startDate, endDate },
    });
  };

  deleteUser(userMobileNumber) {
    return axiosInstance.put(
      `${this.Api}/delete?userMobileNumber=` + userMobileNumber
    );
  }

  updateUserProfile(userProfile, userName, branchName, organisationName) {
    return axiosInstance.put(
      `${this.Api}/update-profile?userProfile=${encodeURIComponent(
        userProfile
      )}&userName=${encodeURIComponent(
        userName
      )}&branchName=${encodeURIComponent(
        branchName
      )}&organisationName=${encodeURIComponent(organisationName)}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  fetchUserDate(userEmail, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/fetch-details`, {
      params: {
        userEmail,
        branchName,
        organisationName,
      },
    });
  }

  editAcess(organisationName, userDTO) {
    return axiosInstance.put(`${this.Api}/edit-access`, userDTO, {
      params: {
        organisationName,
      },
    });
  }
}

export default UserService;
