import { useState, useEffect } from "react";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../../core/service/dataTransferService";
import { ServiceRequest } from "../../core/service/serviceRequest";
import { Link } from "react-router-dom";
import Loader from "../../core/loader/loader";
import { PaginationComponent } from "../../common/pagination";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

const ServiceHistory = ({ vehicleNumber }) => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const serviceRequest = new ServiceRequest();

  useEffect(() => {
    fetchServiceHistory();
  }, [pageIndex]);

  const fetchServiceHistory = () => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .serviceHistory(
        branchName,
        organisationName,
        vehicleNumber,
        pageIndex,
        pageSize
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
        }
      })
      .catch((error) => {
        if (error.response.data.responseCode === 404) {
          setLoading(false);
          setErrorMessage(error.response.data.responseMessage);
        }
        handleErrorResponse("servicehistory", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="mt-5">
      {loading && <Loader loading={loading} />}
      <Table className="table">
        <thead>
          <tr>
            <th scope="col">Invoice Number</th>
            <th scope="col">Date</th>
            <th scope="col">Payment</th>
            <th scope="col">Amount</th>
            <th scope="col">Invoice</th>
          </tr>
        </thead>

        <tbody>
          {errorMessage ? (
            <div className="text-center text-danger">{errorMessage}</div>
          ) : (
            <>
              {servicesData.map((service) => (
                <tr key={service.invoiceData?.invoiceSerialNumber}>
                  <td>
                    {service.invoiceData?.invoiceSerialNumber}
                  </td>
                  <td>{formatDate(service.date)}</td>
                  <td>{service.paymentMethod || "N/A"}</td>
                  <td>{`Rs. ${service.invoiceData?.totalAmount}`}</td>
                  <td>
                    <Link
                      to={`/invoice/${service.invoiceData.invoiceSerialNumber}`}
                      style={{ color: "#127BFF", textDecoration: "none" }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>

      <PaginationComponent
        prevPage={() => onPageChange(pageIndex - 1)}
        nextPage={() => onPageChange(pageIndex + 1)}
        isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
        isPrevDisabled={pageIndex === 0}
        pageIndex={pageIndex}
        totalItems={totalItems}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
    </div>
  );
};

ServiceHistory.propTypes = {
  vehicleNumber: PropTypes.string.isRequired,
};

export default ServiceHistory;
