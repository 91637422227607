import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import UserService from "../../core/service/userService";
import DataTransferService from "../../core/service/dataTransferService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";

const AllBranchesReport = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState("Calender");
  const [loading, setLoading] = useState(false);
  const userService = new UserService();
  const dataTransferService = new DataTransferService();
  const [, setErrorMessage] = useState("");
  const [branchesData, setBranchesData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const downloadCSV = () => {
    const csvHeaders = [
      "Branch Name",
      "Cash Total",
      "Credit Total",
      "Total Amount",
    ];
    const csvRows = branchesData.map((branch) => [
      branch.branchName,
      branch.cashTotal,
      branch.creditTotal,
      branch.totalAmount,
    ]);

    const csvContent = [csvHeaders, ...csvRows]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Branches_Report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStart = startDate.toLocaleDateString();
      const formattedEnd = endDate.toLocaleDateString();
      if (startDate.toDateString() === endDate.toDateString()) {
        setDropdownLabel(`${formattedStart}`);
      } else {
        setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      }

      fetchData(startDate, endDate);
    }
  }, [startDate, endDate]);

  const fetchData = (startDate, endDate) => {
    setLoading(true);
    setErrorMessage("");

    const organisationName = dataTransferService.getSelectedOrganization();
    const formattedStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    const formattedEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    userService
      .allBranchesReport(organisationName, formattedStartDate, formattedEndDate)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setBranchesData(response.data.responseBody.branchesData);
          setBillingData(response.data.responseBody);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("searchServices", error.response);
        setErrorMessage(
          error.response?.data?.responseMessage || "An error occurred"
        );
      });
  };

  const handleDateChange = (update) => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formattedStart = start.toLocaleDateString();
      const formattedEnd = end.toLocaleDateString();
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
    }
  };

  return (
    <div className="invoice-whole-container mt-5">
       {loading && <Loader loading={loading} />}

      <div className="billing-container">
        <div className="box-cash-transactions">
          <div>
            <p className="box-details">Cash Transactions</p>
          </div>
          <hr className="box-cash-transactions-gap" />
          <div className="box-element">
            <p>₹ {billingData?.organisationCashTotal || "0"}</p>
          </div>
        </div>

        <div className="box-cash-transactions">
          <div>
            <p className="box-details">Credit Transactions</p>
          </div>
          <hr className="box-cash-transactions-gap" />
          <div className="box-element">
            <p>₹ {billingData?.organisationCreditTotal || "0"}</p>
          </div>
        </div>
        <div className="box-cash-transactions">
          <div>
            <p className="box-details">Total (Cash + Credit)</p>
          </div>
          <hr className="box-cash-transactions-gap" />
          <div className="box-element">
            <p>₹ {billingData?.organisationTotalAmount || "0"}</p>
          </div>
        </div>
        <div className="box-cash-transactions">
          <div>
            <p className="box-details">Vehicles Serviced</p>
          </div>
          <hr className="box-cash-transactions-gap" />
          <div className="box-element">
            <p>₹ {billingData?.totalVehiclesServiced || "0"}</p>
          </div>
        </div>
      </div>

      <div className="second-container">
        <div className="box-summary-report">
          <div className="summary report">
            <p className="fs-22 fw-600">Summary Report</p>
          </div>

          <div className="d-flex justify-content-end align-items-center p-3">
            <div className="dropdown-select p-3 d-flex align-items-center">
              <span className="fs-14 fw-500 grayish-blue pe-2 text-nowrap">
                Sort by:
              </span>
              <div
                className="dropdown-select pointer"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                  setShowCalendar((prevState) => !prevState);
                }}
              >
                {dropdownLabel}
              </div>
              {showCalendar && (
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateChange}
                  inline
                  minDate={new Date("2024-11-11")}
                  maxDate={new Date()}
                />
              )}
            </div>
          </div>

          <div className="pt-3">
            <Table>
              <thead className="custom-table th">
                <tr>
                  <th>Branch Name</th>
                  <th>Cash Total</th>
                  <th>Credit Total</th>
                  <th>Total(Cash+Credit)</th>
                </tr>
              </thead>
              <tbody className="custom-table td">
                {branchesData.length > 0 ? (
                  branchesData.map((branch) => (
                    <tr key={branch.branchName}>
                      <td className="fs-6">{branch.branchName}</td>
                      <td className="fs-5">
                        ₹ {branch.cashTotal.toLocaleString()}
                      </td>
                      <td className="fs-5">
                        ₹ {branch.creditTotal.toLocaleString()}
                      </td>
                      <td className="fs-5">
                        ₹ {branch.totalAmount.toLocaleString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <div className="text-center text-danger">
                        No data available
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="mt-5 d-flex justify-content-end">
            <button className="download-report-button" onClick={downloadCSV}>
              <FontAwesomeIcon icon={faFileLines} /> Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBranchesReport;
