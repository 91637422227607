import React, { useEffect, useState, useRef } from "react";
import DataTransferService from "../../core/service/dataTransferService";
import Profile from "../../common/profile";
import { DropdownComponent } from "../../common/dropDown";
import Flex from "../../common/flex";
import { Table } from "react-bootstrap";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import ServiceRequest from "../../core/service/serviceRequest";
import Loader from "../../core/loader/loader";
import { Link, useNavigate } from "react-router-dom";
import SearchInput from "../../common/search";
import ViewAll from "../../common/viewAll";
import { TableHeaderComponent } from "../../common/table";
import AllBranchesReport from "../vehicle-data/allBranchesReport";

const Billing = () => {
  const dataTransferService = new DataTransferService();
  const [isFocused, setIsFocused] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [sortOption, setSortOption] = useState("Newest");
  const [loading, setLoading] = useState(true);
  const [allBranchesReport, setAllBranchesReport] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [cashData, setCashData] = useState([]);
  const navigate = useNavigate();
  const serviceRequest = new ServiceRequest();
  const [searchTerm, setSearchTerm] = useState("");
  const isFirstRender = useRef(true);
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const handleChangeOptions = (option) => {
    setSortOption(option);
  };
  const columnNames = [
    "Vehicle Number",
    "Invoice Number",
    "Category",
    "Amount",
    "Payment Type",
    "Invoice",
  ];

  useEffect(() => {
    const storedBranch = dataTransferService.getSelectedBranch();
    const branList = dataTransferService.getListOfBranches();
    if (branList) {
      const parsedBranches = branList.split(",");
      setBranches(parsedBranches);
      if (storedBranch) {
        setSelectedBranch(storedBranch);
        dataTransferService.setSelectedBranch(storedBranch);
      } else if (parsedBranches.length > 0) {
        const initialBranch = parsedBranches[0].trim();
        setSelectedBranch(initialBranch);
        dataTransferService.setSelectedBranch(initialBranch);
      }
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (searchTerm) {
      searchService();
    } else {
      setLoading(false);
      if (dataTransferService.getSelectedBranch() !== "All Branches Report") {
        fetchService(sortOption);
        fetchCash(0, 10, sortOption);
        setAllBranchesReport(false);
        return;
      }
      setAllBranchesReport(true);
    }
  }, [sortOption, searchTerm, selectedBranch]);

  const handleBranchSelect = (branch) => {
    const trimmedBranch = branch.trim();
    setSelectedBranch(trimmedBranch);
    dataTransferService.setSelectedBranch(trimmedBranch);
    window.location.reload();
  };

  const userRole = dataTransferService.getUserInfo().userRole;
  const branchOptions = [
    ...(userRole === "ADMIN" || userRole === "MANAGER"
      ? [
          {
            id: -1,
            value: "All Branches Report",
            label: "All Branches Report",
          },
        ]
      : []),
    ...branches.map((branch, index) => ({
      id: index,
      value: branch.trim(),
      label: branch.trim(),
    })),
  ];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handlenavigate = () => {
    navigate("/select-vehicle");
  };

  const navigateToCreditHistory = () => {
    navigate("/show-credit-history");
  };

  const searchService = () => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .searchServices(
        0,
        10,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        searchTerm
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("searchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const fetchCash = (page, size, order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .paymentStatus(
        page,
        size,
        dataTransferService.getSelectedOrganization(),
        dataTransferService.getSelectedBranch(),
        order
      )
      .then((response) => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          setCashData(response.data.responseBody);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const fetchService = (order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .fetchServices(
        order,
        0,
        10,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
        setTotalItems(0);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-between align-items-center mt-15">
        <DropdownComponent
          value={selectedBranch}
          selected={selectedBranch}
          options={branchOptions}
          onSelect={handleBranchSelect}
          toggleClassName="fs-4 fw-500 branch-change-dropdown custom-arrow"
        />
        <Profile />
      </Flex>

      {!allBranchesReport && (
        <>
          <div className="row show-amount-container mt-5">
            <div className="col-6">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Cash Transactions</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(
                  cashData.cashTotal || 0
                ).toFixed(0)}`}</h3>
              </div>
            </div>

            <div className="col-6">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Credit Transactions</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(
                  cashData.creditTotal || 0
                ).toFixed(0)}`}</h3>
                <button
                  className="btn-no-style link-blue pt-2"
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>
          </div>

          <div className="white-container mt-3 services-card-radius p-4">
            <h3 className="padding-heading fw-600">Recent Services</h3>
            <div className="d-flex justify-content-end mt-2">
              <SearchInput
                value={searchTerm}
                onChange={handleSearchChange}
                clearSearch={clearSearch}
                isFocused={isFocused}
                setIsFocused={setIsFocused}
                placeholder="Search"
                className="w-25"
              />

              {!searchTerm && (
                <DropdownComponent
                  value={sortOption}
                  selected={
                    <Flex className="d-flex align-items-center">
                      <span className="fs-12 fw-400 grayish-blue pe-2">
                        Sort by:
                      </span>
                      {sortOption}
                    </Flex>
                  }
                  options={selectOptions}
                  onSelect={handleChangeOptions}
                  toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
                />
              )}
            </div>

            <div className="d-flex justify-content-end me-4 mb-3 mt-32">
              <button
                className="btn btn-outline custom-btn-outline"
                onClick={handlenavigate}
              >
                + New Service
              </button>
            </div>

            <div className="table-responsive">
              <Table className="table">
                <thead>
                  <TableHeaderComponent columnNames={columnNames} />
                </thead>
                <tbody>
                  {errorMessage ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <div className="text-center text-danger">
                          {errorMessage}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    servicesData.map((service) => (
                      <tr key={service.invoiceData.invoiceSerialNumber}>
                        <td className="p-3">
                          {service.invoiceData.vehicleNumber}
                        </td>
                        <td className="p-3">
                          {service.invoiceData.invoiceSerialNumber}
                        </td>
                        <td className="p-3">{service.vehicleType}</td>
                        <td className="p-3">
                          {service.invoiceData?.totalAmount.toFixed(0) ||
                            service.invoiceData?.amount.toFixed(0)}
                        </td>
                        <td className="p-3">
                          {service.paymentType ? service.paymentType : "N/A"}
                        </td>

                        <td className="p-3">
                          <Link
                            to={`/invoice/${service.invoiceData.invoiceSerialNumber}`}
                            style={{ color: "#127BFF", textDecoration: "none" }}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>

            <div className="mt-4">
              <ViewAll totalItems={totalItems} to="/recent-services" />
            </div>
          </div>
        </>
      )}

      {allBranchesReport && <AllBranchesReport />}
    </div>
  );
};

export default Billing;
