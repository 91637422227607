import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Profile from "../../common/profile";
import { editReturnTrackerValidations } from "../../validations/forms";
import ReturnTrackerRequest from "../../core/service/returnTrackerRequest";
import ReturnTrackerService from "../../core/service/returnTrackerService";
import DataTransferService from "../../core/service/dataTransferService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";
import { Back } from "../../common/back";

const EditReturnTracker = () => {
  const [initialValues, setInitialValues] = useState({
    serialNumber: "",
    manufacturerReferenceId: "",
    tyreManufacturer: "",
    tyreModel: "",
    returnStatus: "",
    customerName: "",
    customerMobileNumber: "",
    createdOn: "",
  });

  const [currentDate, setCurrentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const returnTrackerRequest = new ReturnTrackerRequest();
  const dataTransferService = new DataTransferService();
  const returnTrackerService = new ReturnTrackerService();
  const navigate = useNavigate();
  const { serialNumber } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const branchName = dataTransferService.getSelectedBranch();
        const organisationName = dataTransferService.getSelectedOrganization();
        const response = await returnTrackerRequest.fetchReturnTrackerDetails(
          serialNumber,
          organisationName,
          branchName
        );

        const returnRequest = response.data.responseBody;
        if (returnRequest) {
          setInitialValues({
            serialNumber: returnRequest.serialNumber,
            manufacturerReferenceId: returnRequest.manufacturerReferenceId,
            tyreManufacturer: returnRequest.tyreManufacturer,
            tyreModel: returnRequest.tyreModel,
            returnStatus: returnRequest.returnStatus,
            customerName: returnRequest.customer.customerName,
            customerMobileNumber: returnRequest.customer.customerMobileNumber,
          });
        }
      } catch (error) {
        handleErrorResponse(error.response);
      }
    };

    fetchData();
  }, [serialNumber]);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async (values) => {
    if (values.returnStatus !== initialValues.returnStatus) {
      const formattedData = {
        returnStatus: values.returnStatus,
      };
      submitForm(formattedData);
    } else {
      toast.info("No changes in return status.", { theme: "colored" });
    }
  };

  const submitForm = (formattedData) => {
    setLoading(true);
    const branchName = dataTransferService.getSelectedBranch();
    const organisationName = dataTransferService.getSelectedOrganization();
    returnTrackerService
      .updateReturnTracker(
        organisationName,
        branchName,
        serialNumber,
        formattedData.returnStatus
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          navigate("/return-tracker");
          toast.success(response.data.responseMessage, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse(error.response);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="container new-return-tracker-container p-4 mt-5">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-22 fw-600"> Edit Tracker Details</p>
        </div>
        <div>
          <hr className="pt-3 hr-color" />
        </div>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={editReturnTrackerValidations}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">
                    Serial Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="serialNumber"
                    placeholder="Enter Tyre Serial Number"
                    className="form-control narrow-input placeholder-text"
                    disabled
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-500">
                    Manufacturer Reference Id
                    <span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="manufacturerReferenceId"
                    placeholder="Enter Reference Tracker Id"
                    className="form-control narrow-input placeholder-text"
                    disabled
                  />
                  <ErrorMessage
                    name="manufacturerReferenceId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">
                    Tyre Manufacturer<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="tyreManufacturer"
                    className="form-select narrow-input placeholder-text"
                    disabled
                  >
                    <option value="">select</option>
                    <option value="MICHELIN">MICHELIN</option>
                    <option value="BRIDGESTONE">BRIDGESTONE</option>
                    <option value="GOODYEAR">GOODYEAR</option>
                  </Field>
                  <ErrorMessage
                    name="tyreManufacturer"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col">
                  <label className="label-text fw-500">
                    Tyre Model<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="tyreModel"
                    className="form-select narrow-input placeholder-text"
                    disabled
                  >
                    <option value="">Select Model</option>
                    <option value="MICHELIN_PILOT_SPORT">
                      MICHELIN_PILOT_SPORT
                    </option>
                    <option value="MICHELIN_PRIMACY_4">
                      MICHELIN_PRIMACY_4
                    </option>
                    <option value="MICHELIN_DEFENDER_T_H">
                      MICHELIN_DEFENDER_T_H
                    </option>
                    <option value="BRIDGESTONE_TURANZA_T005">
                      BRIDGESTONE_TURANZA_T005
                    </option>
                    <option value="BRIDGESTONE_DUELER_HL">
                      BRIDGESTONE_DUELER_HL
                    </option>
                    <option value="GOODYEAR_ASSURANCE_WEATHERREADY">
                      GOODYEAR_ASSURANCE_WEATHERREADY
                    </option>
                  </Field>
                  <ErrorMessage
                    name="tyreModel"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">
                    Customer Name<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerName"
                    placeholder="Enter Customer Name"
                    className="form-control narrow-input placeholder-text"
                    disabled
                  />
                  <ErrorMessage
                    name="customerName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-500">
                    Customer Mobile Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerMobileNumber"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control narrow-input placeholder-text"
                    disabled
                  />
                  <ErrorMessage
                    name="customerMobileNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500">Created On</label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control narrow-input placeholder-text"
                    value={currentDate}
                    disabled
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-500">
                    Return Status<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="returnStatus"
                    className="form-select narrow-input placeholder-text"
                  >
                    <option value="" disabled>Select Status</option>
                    <option value="IN_PROCESS">IN_PROCESS</option>
                    <option value="PASSED_QC">PASSED_QC</option>
                    <option value="FAILED_QC">FAILED_QC</option>
                  </Field>
                  <ErrorMessage
                    name="returnStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="mt-4 mb-3 d-flex justify-content-end">
                <button type="submit" className="button-indigo">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditReturnTracker;
