import { React, useState, useEffect } from "react";
import { FaCheckCircle, FaTimesCircle, FaClock } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Flex from "../../common/flex";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DropdownComponent } from "../../common/dropDown";
import Profile from "../../common/profile";
import ReturnTrackerRequest from "../../core/service/returnTrackerRequest";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../../core/service/dataTransferService";
import Loader from "../../core/loader/loader";
import tyreImage from "../../assets/images/tyre.png"
import SearchInput from "../../common/search";
import ViewAll from "../../common/viewAll";
import SubtleBadge, {
  statusByColor,
  toPascalWord,
} from "../../common/subtleBadge";
import { Back } from "../../common/back";

const CustomerReturnTrackers = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Newest");
  const returnTrackerRequest = new ReturnTrackerRequest();
  const dataTransferService = new DataTransferService();
  const [counts, setCounts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);
  const { customerName } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const fetchReturnServices = () => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .fetchReturnCountsForCustomer(organisationName, branchName, customerName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const { IN_PROCESS, PASSED_QC, FAILED_QC } =
            response.data.responseBody;
          setCounts({ IN_PROCESS, PASSED_QC, FAILED_QC });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchReturnServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const fetchReturnRequests = (page, size) => {
    setLoading(true);

    setErrorMessage("");
    returnTrackerRequest
      .searchReturnsByCustomerName(
        organisationName,
        branchName,
        customerName,
        searchTerm,
        page,
        size
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchReturnRequests", error.response);
      });
  };

  const fetchSortedReturns = (order, page, size) => {
    setLoading(true);
    setErrorMessage("");

    returnTrackerRequest
      .sortedReturnRequestsForCustomer(
        organisationName,
        branchName,
        order,
        customerName,
        page,
        size
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchSortedReturns", error.response);
        setErrorMessage(error.response.data.responseMessage);
        setTotalItems(0);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      fetchReturnRequests(pageIndex, pageSize);
    } else {
      fetchReturnServices();
      fetchSortedReturns(selectedOption, pageIndex, pageSize);
    }
  }, [searchTerm, pageIndex, selectedOption]);

  const handleChangeOptions = (option) => {
    setSelectedOption(option);
    setPageIndex(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setPageIndex(0);
  };

  const navigate = useNavigate();

  const handlenavigate = () => {
    navigate("/new-return-tracker");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleSerialClick = (request) => {
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
    setPopupData(request);
  };

  const closePopup = () => {
    setShowPopup(false);
    document.body.style.overflow = '';
  };

  const handlePrintTyreDetails = () => {
    document.title = popupData.serialNumber;
    window.print();
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}
      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="count-container d-flex justify-content-evenly align-items-center mt-5">
        <div className="text-center d-flex count-containers">
          <div className="process-icon">
            <FaClock className="clock-icon fs-2" />
          </div>
          <div className="ms-3">
            <p className="count-head fs-14">In Process</p>
            <p className="fw-bold count-number-style">{counts.IN_PROCESS}</p>
          </div>
        </div>

        <div className="text-center d-flex count-containers">
          <div className="passed-icon">
            <FaCheckCircle className="tick-icon fs-2" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Passed QC</p>
            <p className="fw-bold count-number-style">{counts.PASSED_QC}</p>
          </div>
        </div>

        <div className="text-center d-flex count-containers">
          <div className="failed-icon">
            <FaTimesCircle className="fs-2 wrong-icon" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Failed QC</p>
            <p className="fw-bold count-number-style">{counts.FAILED_QC}</p>
          </div>
        </div>
      </div>

      <div className="card-all-return-requests p-4">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-22 fw-600">{customerName}</p>
        </div>
        <Flex className="mt-4 mb-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
          />

          {!searchTerm && (
            <DropdownComponent
              value={selectedOption}
              selected={
                <Flex className="d-flex align-items-center">
                  <span className="fs-12 fw-400 grayish-blue pe-2">
                    Sort by:
                  </span>
                  {selectedOption}
                </Flex>
              }
              options={selectOptions}
              onSelect={handleChangeOptions}
              toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
            />
          )}
        </Flex>
        <Flex className="align-items-center justify-content-end ">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline mb-4 mt-3"
            onClick={handlenavigate}
          >
            + Create a new Return Tracker
          </button>
        </Flex>

        <Table>
          <thead>
            <tr>
              <th className="fw-500 table-headings">Serial Number</th>
              <th className="fw-500 table-headings">Company</th>
              <th className="fw-500 table-headings">Model Name</th>
              <th className="fw-500 table-headings">Customer name</th>
              <th className="fw-500 table-headings">Created</th>
              <th className="fw-500 table-headings">Status</th>
              <th className="fw-500 table-headings">Edit</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              returnRequests.map((request) => (
                <tr key={request.serialNumber}>
                  <td className="pointer" data-bs-toggle="modal" onClick={() => handleSerialClick(request)}>
                    {request.serialNumber}
                  </td>
                  <td>{request.company}</td>
                  <td className="limited-text">{request.modelName}</td>
                  <td>{request.customerName}</td>
                  <td>{formatDate(request.created)}</td>
                  <td>
                    <SubtleBadge
                      bg={statusByColor(request.returnStatus)}
                      className="fs-14 fw-500"
                    >
                      {toPascalWord(request.returnStatus)}
                    </SubtleBadge>
                  </td>
                  <td>
                    <Link
                      to={`/edit-return-tracker/${request.serialNumber}`}
                      style={{
                        color: "#127BFF",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {showPopup && popupData && (
          <div className="modal show d-block d-flex justify-content-center " id="popupModal" tabIndex="-1" role="dialog" onClick={closePopup}>
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()} >
              <div className="modal-content">
                <div className="ls-data modal-header">
                  <div className="tyre-logo">
                    <img src={tyreImage} />
                  </div>
                  <div className="s-number">
                    {popupData.serialNumber && (
                      <p className="s-number mb-0 fs-3 fw-medium">
                        {popupData.serialNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="status-tab">
                  <div className="row top-box d-flex justify-content-between pb-3 ">
                    {popupData.created && (
                      <div className="col-7 created-style">
                        <p className="fw-normal cs-data fs-6 ps-4">Created On:</p>
                        <p className="data-info fw-light pt-2 fs-6 ps-4">
                          {new Date(popupData.created)
                            .toISOString()
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </p>
                      </div>
                    )}

                    {popupData.returnStatus && (
                      <div className="col-5 status-style">
                        <p className="fw-normal ss-data fs-6">Status:</p>
                        <p className="pt-2 fs-6">
                          <SubtleBadge
                            bg={statusByColor(popupData.returnStatus)}
                            className="fs-14 fw-500"
                          >
                            {toPascalWord(popupData.returnStatus)}
                          </SubtleBadge>
                        </p>
                      </div>
                    )}
                  </div>
                  <hr className="divideline mt-0 mb-0"></hr>
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="pointer printing fs-6" onClick={handlePrintTyreDetails}>
                      <FontAwesomeIcon icon={faPrint} className="icon-spacing" />
                      Print
                    </p>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="details-tab">
                    <div className="container">
                      {popupData.company && (
                        <div className="row">
                          <p className="col-6 data">Tyre Make:</p>
                          <p className="col-6 data-info fw-light fs-6">{popupData.company}</p>
                        </div>
                      )}
                      {popupData.modelName && (
                        <div className="row">
                          <p className="col-6 data">Tyre Model:</p>
                          <p className="col-6 data-info fw-light text-break fs-6">
                            {popupData.modelName}
                          </p>
                        </div>
                      )}
                      {popupData.customerName && (
                        <div className="row">
                          <p className="col-6 data">Customer Name:</p>
                          <p className="col-6 data-info fw-light fs-6">{popupData.customerName}</p>
                        </div>
                      )}
                      {popupData.manufacturerId && (
                        <div className="row">
                          <p className="col-6 data">Manufacturer Id:</p>
                          <p className="col-6 data-info fw-light fs-6">{popupData.manufacturerId}</p>
                        </div>
                      )}
                      {popupData.returnStatus && (
                        <div className="row">
                          <p className="col-6 data">Claim Status:</p>
                          <p className="col-6 data-info fs-6">
                            <SubtleBadge
                              bg={statusByColor(popupData.returnStatus)}
                              className="fs-14 fw-500"
                            >
                              {toPascalWord(popupData.returnStatus)}
                            </SubtleBadge>
                          </p>
                        </div>
                      )}

                    </div>
                    <p className="pt-4 fw-normal note">
                      The return request of the tyre has been accepted by the company. We
                      will replace the tyre once we receive it from the company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4">
          <ViewAll
            totalItems={totalItems}
            to={`/view-all-customer-return-trackers/${customerName}`}
          />
        </div>
      </div>
    </div>
  );
};
export default CustomerReturnTrackers;