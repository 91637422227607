import { useParams } from "react-router-dom";
import Profile from "../../common/profile";
import UserService from "../../core/service/userService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { useEffect, useState } from "react";
import DataTransferService from "../../core/service/dataTransferService";
import Loader from "../../core/loader/loader";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { editUserValidations } from "../../validations/forms";
import {
  DisabledDropdownComponent,
  DropdownComponent,
} from "../../common/dropDown";

const EditUser = () => {
  const { userEmail } = useParams();
  const [userData, setUserData] = useState(null);
  const [matchingBranchId, setMatchingBranchId] = useState(0);
  const [branches, setBranches] = useState([]);
  const dataTransferService = new DataTransferService();
  const selectedOrg = dataTransferService.getSelectedOrganization();
  const organisationDTO = dataTransferService.getOrganisationDTO();
  const selectedBran = dataTransferService.getSelectedBranch();
  const [branchNames, setBranchNames] = useState([]);

  const fetchUserData = (email) => {
    setLoading(true);
    userService
      .fetchUserDate(email, selectedBran, selectedOrg)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setUserData(response.data.responseBody);
          let selectedBranchNames = [];
          Object.keys(response.data.responseBody.organisationBranchMap).forEach(
            (key) => {
              const branchDataArray =
                response.data.responseBody.organisationBranchMap[key];

              if (
                Array.isArray(branchDataArray) &&
                branchDataArray.length > 0
              ) {
                selectedBranchNames = branchDataArray.map(
                  (branch) => branch.branchName
                );

                const allBranchData = branchDataArray.map((branch) => ({
                  id: branch.id,
                  name: branch.branchName,
                  address: branch.branchAddress,
                  phone: branch.branchMobileNumber,
                }));

                setBranches(allBranchData);
                setBranchNames(selectedBranchNames);
              }
            }
          );

          userService.fetchBranches(selectedOrg).then((response) => {
            if (response.data.responseCode === 200) {
              const branchData = response.data.responseBody.map(
                (branch, index) => ({
                  id: index + 1,
                  name: branch.branchName,
                  address: branch.branchAddress,
                  phone: branch.branchMobileNumber,
                })
              );

              setBranches(branchData);
              const matchingBranches = branchData.filter((branch) =>
                selectedBranchNames.includes(branch.name)
              );

              if (matchingBranches.length > 0) {
                const matchingBranchIds = matchingBranches.map(
                  (branch) => branch.id
                );

                setMatchingBranchId(matchingBranchIds);
              } else {
                setMatchingBranchId([]);
              }
            }
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("admin-fetch-users", error.response);
      });
  };

  useEffect(() => {
    if (matchingBranchId !== 0) {
      console.error(`Updated Matching Branch ID: ${matchingBranchId}`);
    }
  }, [matchingBranchId]);

  const initialValues = {
    userName: userData?.userName || "",
    userEmail: userData?.userEmail || "",
    userRole: userData?.userRole || "",
    organisationName: organisationDTO?.organisationName || selectedOrg || "",
    branchNames: matchingBranchId || [],
    userMobileNumber: userData?.userMobileNumber || "",
  };

  const [loading, setLoading] = useState(false);
  const userService = new UserService();

  useEffect(() => {
    if (userEmail) {
      fetchUserData(userEmail);
    }
  }, [userEmail]);

  const handleBranchSelection = (branch, values, setFieldValue) => {
    const branchId = branch.id;
    if (values.branchNames.includes(branchId)) {
      setFieldValue(
        "branchNames",
        values.branchNames.filter((id) => id !== branchId)
      );
    } else {
      setFieldValue("branchNames", [...values.branchNames, branchId]);
    }
  };

  const roleOptions = [
    { id: 1, label: "Manager", value: "Manager" },
    { id: 2, label: "Store_Supervisor", value: "Store_Supervisor" },
  ];

  const handleSubmit = async (values) => {
    setLoading(true);
    const orgName = organisationDTO
      ? organisationDTO.organisationName
      : selectedOrg;
    const transformedValues = {
      ...values,
      organisationName: orgName,
      userRole: values.userRole.toUpperCase(),
      branchNames: values.branchNames.map((id) => {
        const branch = branches.find((branch) => branch.id === id);
        return branch ? branch.name : "";
      }),
    };
    userService
      .editAcess(selectedOrg, transformedValues)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          toast.success(`User invited successfully ${values.userName}`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("edit-user", error.response);
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center pt-3">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>

      <div className="mt-5 invoice-whole-container">
        {loading && <Loader loading={loading} />}

        {!loading && userData && (
          <Formik
            initialValues={initialValues}
            validationSchema={editUserValidations}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="edit-branches-container p-4">
                  <p className="fs-4 fw-600">Edit Employee Data</p>
                  <p className="fs-5 fw-500 mt-4">Employee Details</p>

                  <div className="d-flex align-items-center justify-content-start mt-4">
                    <div className="me-4" style={{ width: "300px" }}>
                      <label className="email-text">
                        Enter Employee Name{" "}
                        <span className="color-star">*</span>
                      </label>
                      <Field
                        type="text"
                        name="userName"
                        placeholder="Enter employee name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="userName"
                        component="div"
                        className="error-text text-danger"
                      />
                    </div>

                    <div style={{ width: "300px" }}>
                      <label className="email-text">
                        Enter Employee Email{" "}
                        <span className="color-star">*</span>
                      </label>
                      <Field
                        type="email"
                        name="userEmail"
                        placeholder="Enter employee email address"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="userEmail"
                        component="div"
                        className="error-text text-danger"
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-start mt-4">
                    <div style={{ width: "300px" }}>
                      <label className="email-text">
                        Enter Employee Mobile Number{" "}
                        <span className="color-star">*</span>
                      </label>
                      <Field
                        type="text"
                        name="userMobileNumber"
                        placeholder="Enter employee mobile number"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="userMobileNumber"
                        component="div"
                        className="error-text text-danger"
                      />
                    </div>
                  </div>

                  <div className="note-styling fs-14 fw-normal">
                    Note: This employee can access the billing data, create new
                    bills for services offered.
                  </div>

                  <div className="mt-4">
                    <p className="email-text">
                      Employee Role<span className="color-star">*</span>
                    </p>
                    <DropdownComponent
                      selected={values.userRole || "Select Role"}
                      options={roleOptions}
                      onSelect={(selectedOption) =>
                        setFieldValue("userRole", selectedOption)
                      }
                      toggleClassName="fs-14 fw-semibold custom-invite-dropdown custom-arrow"
                    />
                    <ErrorMessage
                      name="userRole"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>

                  <div className="mt-4">
                    <p className="email-text">
                      Select Organization<span className="color-star">*</span>
                    </p>
                    {organisationDTO ? (
                      <DisabledDropdownComponent
                        toggleClassName="fs-14 fw-bold disabled-dropdown custom-arrow"
                        selected={organisationDTO.organisationName}
                      />
                    ) : selectedOrg ? (
                      <DisabledDropdownComponent
                        toggleClassName="fs-14 fw-bold disabled-dropdown custom-arrow w-25"
                        selected={selectedOrg}
                      />
                    ) : (
                      <p className="text-danger">No organization selected</p>
                    )}
                  </div>

                  <div className="select-branches-outer-container mt-4 d-flex flex-column p-5 pb-32">
                    <div className="d-flex align-items-center justify-content-start">
                      {branches.map((branch) => (
                        <div
                          key={branch.id}
                          className={`me-3 select-branches-inner-container pt-3 ps-4 d-flex align-items-center justify-content-evenly branch-data ${
                            values.branchNames.includes(branch.id)
                              ? "selected"
                              : ""
                          }`}
                        >
                          <div className="branch-fields">
                            <p className="fs-6 fw-500">{branch.name}</p>
                            <p className="fs-15 fw-400">{branch.address}</p>
                            <p className="fs-14 fw-400">Ph: {branch.phone}</p>
                          </div>
                          <input
                            type="checkbox"
                            checked={values.branchNames.includes(branch.id)}
                            onChange={() =>
                              handleBranchSelection(
                                branch,
                                values,
                                setFieldValue
                              )
                            }
                            className="ms-3"
                          />
                        </div>
                      ))}
                    </div>
                    <ErrorMessage
                      name="branchNames"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-end mt-5">
                    <button type="submit" className="p-2 button-teal w-25">
                      Proceed
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default EditUser;
