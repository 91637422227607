import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserService from "../core/service/userService";
import { handleErrorResponse } from "../core/tokenInterceptor/axiosInstance";
import { setPasswordValidations } from "../validations/forms";
import { useFormik } from "formik";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../core/loader/loader";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const SetPassword = () => {
  const [loading, setLoading] = useState(false);
  const userService = new UserService();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams.get("userEmail");

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: setPasswordValidations,
    onSubmit: (values) => {
      const userPasswordData = {
        userPassword: values.newPassword,
      };
      setLoading(true);
      userService
        .setPassword(userPasswordData, userEmail)
        .then((response) => {
          if (response.data.responseCode === 200) {
            setLoading(false);
            navigate("/proceed-to-login");
            toast.success(
              `Password created successfully. Please click on okay to login.`,
              {
                theme: "colored",
              }
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          handleErrorResponse("set-password", error.response);
        });
    },
  });

  const handleNewPasswordClick = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const handleConfirmPasswordClick = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <div className="page-container">
      <div className="login-container p-4">
        <div className="mb-5">
          <h2 className="login-text">
            Email Verification Successful. Enter Your New Password
          </h2>
        </div>

        <div className="form-container">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label className="email-text" htmlFor="newPassword">
                New Password<span className="color-star">*</span>
              </label>
              <div className="input-wrapper">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  id="newPassword"
                  placeholder="Enter new password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.newPassword && formik.errors.newPassword
                      ? "error"
                      : ""
                  }
                />
                <span className="icons" onClick={handleNewPasswordClick}>
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                  />
                </span>
              </div>
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="error">{formik.errors.newPassword}</div>
              ) : null}
            </div>

            <div className="mb-3 password-container">
              <label className="pw-text" htmlFor="confirmNewPassword">
                Confirm New Password<span className="color-star">*</span>
              </label>
              <div className="input-wrapper">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmNewPassword"
                  id="confirmNewPassword"
                  placeholder="Confirm new password"
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.confirmNewPassword &&
                    formik.errors.confirmNewPassword
                      ? "error"
                      : ""
                  }
                />
                <span className="icons" onClick={handleConfirmPasswordClick}>
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                  />
                </span>
              </div>
              {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword ? (
                <div className="error">{formik.errors.confirmNewPassword}</div>
              ) : null}
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="p-2 button-styling"
                disabled={loading}
              >
                {loading ? <Loader loading={loading} /> : "Confirm"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
