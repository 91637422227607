import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { heavyVehicleBillingValidations } from "../../validations/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import DataTransferService from "../../core/service/dataTransferService";
import Profile from "../../common/profile";
import Flex from "../../common/flex";
import InvoiceService from "../../core/service/invoiceService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../core/loader/loader";
import { useNavigate } from "react-router-dom";
import {
  InputField,
  SelectField,
  ServiceField,
} from "../../validations/inputFields";
import { Back } from "../../common/back";

const HeavyVehicleBilling = () => {
  const initialValues = {
    services: {
      wheelBalancing: {
        selected: false,
        wheelBalancingQuantity: "",
        wheelBalancingPrice: "",
      },
      weightBalancing: {
        selected: false,
        weightBalancingQuantity: "",
        weightBalancingPrice: "",
      },
      tyrePuncture: {
        selected: false,
        tyrePunctureQuantity: "",
        tyrePuncturePrice: "",
      },
      others: {
        selected: false,
        othersQuantity: "",
        othersPrice: "",
      },
      freight: {
        selected: "",
        freightCharge: "",
      },
    },
    customerName: "",
    customerMobileNumber: "",
    driverMobileNumber: "",
    vehicleNumber: "",
    vehicleManufacturer: "",
    customVehicleManufacturer: "",
    numberOfVehicleWheels: "",
    vehicleWheels: "",
    vehicleModel: "",
    customVehicleModel: "",
    vehicleOdometerReading: "",
    vehicleAverageKmsPerDay: "",
    createdOn: "",
    useGstInvoice: false,
    gstinNumber: "",
    businessName: "",
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const invoiceService = new InvoiceService();
  const dataTransferService = new DataTransferService();
  const [currentDate, setCurrentDate] = useState("");
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = (values) => {
    const selectedServices = [
      values.services.wheelBalancing.selected,
      values.services.weightBalancing.selected,
      values.services.tyrePuncture.selected,
      values.services.others.selected,
    ];
    const isAnyServiceSelected = selectedServices.some((service) => service);

    if (!isAnyServiceSelected) {
      toast.error("Please select at least one service.", {
        theme: "colored",
      });
      return;
    }
    const formattedData = {
      customerDTO: {
        customerName: values.customerName,
        customerMobileNumber: values.customerMobileNumber,
      },
      ...((values.businessName || values.gstinNumber) && {
        businessDetailsDTO: {
          businessName: values.businessName,
          gstinNumber: values.gstinNumber,
        },
      }),
      vehicleDTO: {
        vehicleNumber: values.vehicleNumber,
        driverMobileNumber: values.driverMobileNumber,
        vehicleManufacturer:
          values.vehicleManufacturer === "Other"
            ? "OTHER"
            : values.vehicleManufacturer.toUpperCase(),
        customVehicleManufacturer:
          values.vehicleManufacturer == "Other"
            ? values.customVehicleManufacturer.toUpperCase()
            : "",
        vehicleModel: values.vehicleModel.toUpperCase(),
        customVehicleModel: values.customVehicleModel
          ? values.customVehicleModel.toUpperCase()
          : "",
        vehicleOdometerReading: values.vehicleOdometerReading,
        vehicleAverageKmsPerDay: values.vehicleAverageKmsPerDay,
        vehicleWheels: Number(values.numberOfVehicleWheels),
        vehicleType: "TRUCK",
      },
      servicesDTO: {
        weightBalancing: values.services?.weightBalancing?.selected || false,
        weightBalancingPrice:
          values.services?.weightBalancing?.weightBalancingPrice || 0,
        weightBalancingQuantity:
          values.services?.weightBalancing?.weightBalancingQuantity || 0,
        wheelBalancing: values.services?.wheelBalancing?.selected || false,
        wheelBalancingPrice:
          values.services?.wheelBalancing?.wheelBalancingPrice || 0,
        wheelBalancingQuantity:
          values.services?.wheelBalancing?.wheelBalancingQuantity || 0,
        tyrePuncture: values.services?.tyrePuncture?.selected || false,
        tyrePuncturePrice:
          values.services?.tyrePuncture?.tyrePuncturePrice || 0,
        tyrePunctureQuantity:
          values.services?.tyrePuncture?.tyrePunctureQuantity || 0,
        others: values.services.others.selected,
        othersQuantity: values.services.others.othersQuantity || 0,
        othersPrice: values.services.others.othersPrice || 0,
        freight: values.services.freight.selected === "Yes",
        freightCharge:
          values.services.freight.selected === "Yes"
            ? values.services.freight.freightCharge || 0
            : 0,
      },
    };
    submitForm(formattedData);
  };

  const submitForm = (formattedData) => {
    setLoading(true);
    invoiceService
      .generateheavyWheelerInvoice(
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        formattedData
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          navigate(`/invoice/${response.data.responseBody}`);
          toast.success("Invoice Generated successfully", {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("generate-invoice", error.response);
      });
  };

  return (
    <div className="whole-container p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <div className="box-container p-4 d-flex justify-content-start flex-direction-column">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-4 fw-600">Bill New Service</p>
        </div>

        <hr className="mt-3" />

        <Formik
          initialValues={initialValues}
          validationSchema={heavyVehicleBillingValidations}
          onSubmit={handleSubmit}
          validateOnChange={true}
        >
          {(formik) => (
            <Form>
              <div className="row pt-3">
                <div className="col">
                  <InputField
                    label="Customer Name"
                    name="customerName"
                    type="text"
                    placeholder="Enter Customer Name"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Customer Mobile Number"
                    name="customerMobileNumber"
                    type="text"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Vehicle Number"
                    name="vehicleNumber"
                    type="text"
                    placeholder="Enter Vehicle Number"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <SelectField
                    label="Vehicle manufacturer"
                    name="vehicleManufacturer"
                    options={[
                      "Toyota",
                      "Honda",
                      "Ford",
                      "BMW",
                      "AUDI",
                      "Other",
                    ]}
                    className="w-75"
                  
                  />
                  {formik.values.vehicleManufacturer === "Other" && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Vehicle Manufacturer"
                        name="customVehicleManufacturer"
                        type="text"
                        placeholder="Enter Vehicle Manufacturer"
                        className="form-control w-75"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <SelectField
                    label="Vehicle Wheels"
                    name="numberOfVehicleWheels"
                    options={["4", "6", "8", "12", "14", "16"]}
                    className="w-75"
                  />
                </div>
                <div className="col">
                  <SelectField
                    label="Vehicle Model"
                    name="vehicleModel"
                    options={["Corolla", "Civic", "Mustang", "Other"]}
                    className="w-75"
                  />
                  {formik.values.vehicleModel === "Other" && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Vehicle Model"
                        name="customVehicleModel"
                        type="text"
                        placeholder="Vehicle Model"
                        className="form-control w-75 "
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Vehicle Odometer Reading"
                    name="vehicleOdometerReading"
                    type="number"
                    placeholder="Enter Vehicle Odometer Reading"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Vehicle Average KM's Per Day"
                    name="vehicleAverageKmsPerDay"
                    type="number"
                    placeholder="Enter Vehicle Average KM's Per Day"
                    className="form-control w-75"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <SelectField
                    label="Is The Vehicle With Freight?"
                    name="services.freight.selected"
                    options={["Yes", "No"]}
                    defaultOption="Choose If The Vehicle Is With Freight or Not?"
                    className="w-75"
                  />
                </div>
                {formik.values.services?.freight?.selected === "Yes" && (
                  <div className="col-6">
                    <InputField
                      label="Freight Charge"
                      name="services.freight.freightCharge"
                      type="number"
                      placeholder="Enter Freight Price"
                      className="form-control w-75"
                    />
                  </div>
                )}
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Driver Mobile Number"
                    name="driverMobileNumber"
                    type="text"
                    placeholder="Enter Driver Mobile Number"
                    className="form-control w-75"
                  />
                </div>

                <div className="col">
                  <label className="fs-14 fw-500" htmlFor="createdOn">
                    Created On
                  </label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control w-75"
                    value={currentDate}
                    disabled
                    id="createdOn"
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="mt-3 fw-bold pb-1 fs-14 fw-500">
                    Select the services<span className="color-star">*</span>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2"
                      style={{ color: "#cfcfcf" }}
                    />
                  </p>
                  <p className="muted-grayish-blue fs-14 fw-400">
                    Click all the services performed
                  </p>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="wheelBalancing"
                      serviceTitle="Wheel Alignment"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="weightBalancing"
                      serviceTitle="Weight Balancing"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyrePuncture"
                      serviceTitle="Tyre Puncture"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField serviceName="others" serviceTitle="Other" />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col d-flex align-items-center">
                  <Field
                    type="checkbox"
                    name="useGstInvoice"
                    className="form-check-input me-2"
                    id="useGstInvoice"
                  />
                  <label
                    className="form-check-label mb-0 fs-14 fw-500"
                    htmlFor="useGstInvoice"
                  >
                    Use GST Invoice
                  </label>
                </div>
              </div>

              {formik.values.useGstInvoice && (
                <div className="row mt-3">
                  <div className="col">
                    <InputField
                      label="GSTIN Number"
                      name="gstinNumber"
                      type="text"
                      placeholder="Enter GSTIN Number"
                      className="form-control w-75"
                      id="gstinNumber"
                    />
                  </div>
                  <div className="col">
                    <InputField
                      label="Business Name"
                      name="businessName"
                      type="text"
                      placeholder="Enter Business Name"
                      className="form-control w-75"
                      id="businessName"
                    />
                  </div>
                </div>
              )}

              <div className="col mt-3">
                <button type="submit" className="button-indigo">
                  Generate Invoice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default HeavyVehicleBilling;
