import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../components/login";
import AuthGuard from "../core/guard/authguard";
import LoginGuard from "../core/guard/loginGuard";
import Invoice from "../components/billing/invoice";
import ManageBranches from "../components/admin-access/manageBranches";
import Billing from "../components/billing/billing";
import SelectVehicle from "../components/billing/selectVehicle";
import ReviewOrganisation from "../components/setup-organisation/reviewOrganisation";
import Organisation from "../components/setup-organisation/organisation";
import ViewBranches from "../components/setup-organisation/viewBranches";
import CreateBranches from "../components/setup-organisation/createBranches";
import InviteUsers from "../components/setup-organisation/inviteUsers";
import InviteForm from "../components/setup-organisation/inviteForm";
import RecentServices from "../components/billing/recentService";
import ServiceAlerts from "../components/service-alerts/serviceAlerts";
import AlertsAndHistory from "../components/service-alerts";
import EditUser from "../components/admin-access/editUser";
import SetPassword from "../components/setPassword";
import WheelerBilling from "../components/billing/wheelerBilling";
import HeavyVehicleBilling from "../components/billing/heavyVehicleBilling";
import NotFound from "../components/pageNotFound";
import ReturnTrackerHome from "../components/return-tracker/returnTrackerHome";
import ShowCreditHistory from "../components/billing/showCreditHistory";
import ForgotPassword from "../components/forgotPassword";
import NewReturnTracker from "../components/return-tracker/newReturnTracker";
import EditReturnTracker from "../components/return-tracker/editReturnTracker";
import ViewAllReturnRequests from "../components/return-tracker/viewAllReturnRequests";
import CustomerReturnTrackers from "../components/return-tracker/customerReturnTrackers";
import ViewAllCustomerReturnTrackers from "../components/return-tracker/viewAllCustomerReturnTrackers";
import AllBranchesReport from "../components/vehicle-data/allBranchesReport";
import SendBulkAlerts from "../components/vehicle-data/sendBulkAlerts";
import VehicleBilling from "../components/vehicle-data/vehicleBilling";
import UpdateProfile from "../components/settings";
import ProceedToLogin from "../components/proceedToLogin";

const RetailersRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginGuard element={Login} />} />
      <Route path="/edit-user/:userEmail" element={<AuthGuard element={EditUser} />} />
      <Route path="/" element={<AuthGuard element={Billing} />} />
      <Route
        path="/select-vehicle"
        element={<AuthGuard element={SelectVehicle} />}
      />
      <Route
        path="/manage-branches/:organisationName"
        element={<AuthGuard element={ManageBranches} />}
      />
      <Route
        path="/create-organisation"
        element={<AuthGuard element={Organisation} />}
      />
      <Route
        path="/review-organisation"
        element={<AuthGuard element={ReviewOrganisation} />}
      />
      <Route
        path="/view-branches"
        element={<AuthGuard element={ViewBranches} />}
      />
      <Route
        path="/create-branches"
        element={<AuthGuard element={CreateBranches} />}
      />
      <Route
        path="/invite-users"
        element={<AuthGuard element={InviteUsers} />}
      />
      <Route path="/invite-form" element={<AuthGuard element={InviteForm} />} />
      <Route
        path="/recent-services"
        element={<AuthGuard element={RecentServices} />}
      />
      <Route
        path="/service-alerts"
        element={<AuthGuard element={ServiceAlerts} />}
      />
      <Route
        path="/alerts-history/:vehicleNumber"
        element={<AuthGuard element={AlertsAndHistory} />}
      />
      <Route path="/set-password" element={<SetPassword />} />
      <Route
        path="/wheeler-billing"
        element={<AuthGuard element={WheelerBilling} />}
      />
      <Route
        path="/create-organistion"
        element={<AuthGuard element={Organisation} />}
      />
      <Route path="/organisation" element={<Organisation />} />
      <Route
        path="/invoice/:invoiceSerialNumber"
        element={<AuthGuard element={Invoice} />}
      />
      <Route
        path="/heavy-vehicle-billing"
        element={<AuthGuard element={HeavyVehicleBilling} />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="errors/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
      <Route
        path="/return-tracker"
        element={<AuthGuard element={ReturnTrackerHome} />}
      />
      <Route
        path="/show-credit-history"
        element={<AuthGuard element={ShowCreditHistory} />}
      />
      <Route
        path="/new-return-tracker"
        element={<AuthGuard element={NewReturnTracker} />}
      />
      <Route
        path="/vehicle-billing"
        element={<AuthGuard element={VehicleBilling} />}
      />
      <Route
        path="/all-branches-report"
        element={<AuthGuard element={AllBranchesReport} />}
      />

      <Route
        path="/edit-return-tracker/:serialNumber"
        element={<AuthGuard element={EditReturnTracker} />}
      />
      <Route
        path="/view-all-return-requests"
        element={<AuthGuard element={ViewAllReturnRequests} />}
      />
      <Route
        path="/customer-return-trackers/:customerName"
        element={<AuthGuard element={CustomerReturnTrackers} />}
      />
      <Route
        path="/view-all-customer-return-trackers/:customerName"
        element={<AuthGuard element={ViewAllCustomerReturnTrackers} />}
      />
      <Route
        path="/promotion-alerts"
        element={<AuthGuard element={SendBulkAlerts} />}
      />

      <Route path="/settings" element={<AuthGuard element={UpdateProfile} />} />
      <Route path="/proceed-to-login" element={<ProceedToLogin />} />

    </Routes>
  );
};
export default RetailersRoutes;
