import React, { useRef, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../core/loader/loader";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../../core/service/dataTransferService";
import Flex from "../../common/flex";
import Profile from "../../common/profile";
import ServiceRequest from "../../core/service/serviceRequest";
import InvoiceService from "../../core/service/invoiceService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState("");
  const paymentTypeRef = useRef(null);
  const [creditStatus, setCreditStatus] = useState("unsettled");
  const serviceRequest = new ServiceRequest();
  const invoiceService = new InvoiceService();
  const dataTransferService = new DataTransferService();
  const { invoiceSerialNumber } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    serviceRequest
      .fetchInvoice(
        invoiceSerialNumber,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setInvoiceData(response.data.responseBody);
          if (invoiceData?.creditStatus) {
            setCreditStatus(invoiceData.creditStatus.toLowerCase());
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchData", error.response);
      });
  };

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!invoiceData) {
    return <p>No invoice data found.</p>;
  }

  const isoDateString = new Date(invoiceData.createdOn).toLocaleDateString(
    "en-GB"
  );
  const upcominAlertDate = invoiceData.upcomingAlert
    ? invoiceData.upcomingAlert.split("-").reverse().join("/")
    : null;

  const handleSaveAndPrint = () => {
    document.title = invoiceData.invoiceSerialNumber;
    window.print();
  };

  const handleChangeCreditStatus = () => {
    setCreditStatus((prevStatus) =>
      prevStatus === "unsettled" ? "settled" : "unsettled"
    );
  };

  const handlePaymentTypeChange = async () => {
    const newPaymentType = paymentTypeRef.current.value;
    setPaymentType(newPaymentType);

    try {
      await invoiceService.updatePaymentType(
        invoiceSerialNumber,
        newPaymentType.toUpperCase(),
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      );
    } catch (error) {
      console.error("Error updating payment type:", error);
      handleErrorResponse("invoice", error.response);
    }
  };

  const updateCreditStatus = async () => {
    setCreditStatus(creditStatus);
    try {
      await invoiceService.updateCreditstatus(
        creditStatus.toUpperCase(),
        invoiceSerialNumber,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      );
      toast.success("Credit status updated successfully.", {
        theme: "colored",
      });
    } catch (error) {
      console.error("Error updating credit status:", error);
      handleErrorResponse("invoice", error.response);
    }
  };

  const currentStatus = creditStatus || invoiceData.creditStatus?.toLowerCase();
  const isSettled = currentStatus === "settled";

  const serviceRows = [
    {
      condition: invoiceData.serviceDTO.weightBalancingQuantity,
      name: "Weight Balancing",
      quantity: invoiceData.serviceDTO.weightBalancingQuantity,
      price: invoiceData.serviceDTO.weightBalancingPrice,
      totalPrice: invoiceData.serviceDTO.weightBalancingTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.wheelBalancingQuantity,
      name: "Wheel Balancing",
      quantity: invoiceData.serviceDTO.wheelBalancingQuantity,
      price: invoiceData.serviceDTO.wheelBalancingPrice,
      totalPrice: invoiceData.serviceDTO.wheelBalancingTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.tyrePunctureQuantity,
      name: "Tyre Puncture",
      quantity: invoiceData.serviceDTO.tyrePunctureQuantity,
      price: invoiceData.serviceDTO.tyrePuncturePrice,
      totalPrice: invoiceData.serviceDTO.tyrePunctureTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.othersQuantity,
      name: "Other services",
      quantity: invoiceData.serviceDTO.othersQuantity,
      price: invoiceData.serviceDTO.othersPrice,
      totalPrice: invoiceData.serviceDTO.othersTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.freight,
      name: "Freight Charge",
      totalPrice: invoiceData.serviceDTO.freightCharge,
    },
  ].filter((row) => row.condition);

  return (
    <div className="p-4">
      <Flex className="profile d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <Flex className="invoice-whole-container mt-5">
        <div className="left-section">
          <div className="in-containerpage">
            <div className="businessdetails d-flex justify-content-between align-items-top-center">
              <div className="imageclass">
                <img
                  className="img-fluid"
                  src={
                    dataTransferService.getBloburl() +
                    invoiceData.organisationProfile
                  }
                  alt="Invoice"
                  width={130}
                  height={130}
                />
              </div>
              <div className="details1">
                <p className="fs-14 fw-600">
                  {dataTransferService.getSelectedOrganization()}
                </p>
                <p className="dark-grayish-blue fs-12 fw-normal">
                  {dataTransferService.getSelectedBranch()}
                </p>
                <p className="dark-grayish-blue fs-12 fw-normal">
                  {invoiceData.branchAddress}
                </p>
                <p className="dark-grayish-blue fs-12 fw-normal">
                  {invoiceData.branchMobileNumber}
                </p>
              </div>
              <div className="details2 text-right">
                <p className="fs-14 fw-500 dark-slate-gray">
                  Invoice number: {invoiceData.invoiceSerialNumber}
                </p>
                <p className="dark-grayish-blue fs-12 fw-600">
                  Date: {isoDateString}
                </p>
              </div>
            </div>

            <div className="bill-container border border-light mt-32">
              <div className="tobill">
                <p className="dark-grayish-blue fs-12 fw-normal">To</p>
                <p className="fs-14 fw-600">{invoiceData.customerName}</p>
                {invoiceData.gstinNumber && (
                  <p className="fs-14 fw-600">
                    GSTIN : {invoiceData.gstinNumber}
                  </p>
                )}
                <p className="dark-grayish-blue fs-12 fw-normal pt-1">
                  {invoiceData.vehicleNumber}
                </p>
                <p className="dark-grayish-blue fs-12 fw-normal pt-1">
                  {invoiceData.vehicleModel}
                </p>
                <p className="dark-grayish-blue fs-12 fw-normal pt-1">
                  {invoiceData.customerMobileNumber}
                </p>
                <p className="dark-grayish-blue fs-12 fw-normal pt-4">Note:</p>
                {paymentType || invoiceData.paymentType ? (
                  <p className="fs-12 fw-500 pt-1">
                    For optimized and smooth rides, please ensure the next wheel
                    alignment is after 4000kms from the current reading.
                  </p>
                ) : (
                  <p className="fs-12 fw-500 pt-1">
                    For optimized and smooth rides, please ensure the next wheel
                    alignment is on or before {upcominAlertDate || "dd/mm/yy"}.
                  </p>
                )}
              </div>
              <table className="table custom-table mt-4">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Services</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceRows.map((row) => (
                    <tr key={row.name}>
                      <td>{serviceRows.indexOf(row) + 1}</td>
                      <td>{row.name}</td>
                      <td>
                        {row.name === "Freight Charge" ? (
                          ""
                        ) : (
                          <>
                            {row.quantity}
                            <p className="dark-grayish-blue">Unit(s)</p>
                          </>
                        )}
                      </td>
                      <td>
                        {row.name === "Freight Charge" ? (
                          ""
                        ) : (
                          <>₹ {row.price}</>
                        )}
                      </td>
                      <td>₹ {row.totalPrice}</td>
                    </tr>
                  ))}

                  <tr className="total-row">
                    <td
                      colSpan="3"
                      className="text-right font-weight-bold"
                    ></td>
                    <td>
                      <span className="fw-500">
                        {invoiceData.gstinNumber ? "Sub Total" : "Total "}
                      </span>
                    </td>
                    <td>
                      <span
                        className="fw-500"
                        style={{ fontFamily: "Inter, sans-serif" }}
                      >
                        ₹ {invoiceData.amount.toFixed(0)}
                      </span>
                    </td>
                  </tr>
                  {invoiceData.gstinNumber && (
                    <>
                      <tr className="total-row">
                        <td colSpan="3"></td>
                        <td>
                          <span className="fw-500">CGST 9% </span>
                        </td>
                        <td className="fw-500">
                          ₹ {invoiceData.cgst.toFixed(0)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="3"></td>
                        <td>
                          <span className="fw-500">SGST 9% </span>
                        </td>
                        <td className="fw-500">
                          ₹ {invoiceData.sgst.toFixed(0)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="3"></td>
                        <td>
                          <span className="fw-bold">Total</span>
                        </td>
                        <td className="fw-bold">
                          ₹ {invoiceData.totalAmount.toFixed(0)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="right-section">
          <div className="right-container d-flex flex-column">
            <p className="h5">
              <strong>
                {paymentType ||
                invoiceData.paymentType === "CASH" ||
                invoiceData.paymentType === "CREDIT"
                  ? "Customer Details"
                  : "Next Service Alert"}
              </strong>
            </p>
            <hr />

            <div className="alert-payment-container d-flex flex-column">
              {(paymentType || invoiceData.paymentType) && (
                <>
                  <p className="mt-3">
                    Customer Name: {invoiceData.customerName}
                  </p>
                  <p className="mt-3">
                    Mobile Number: {invoiceData.customerMobileNumber}
                  </p>
                  <p className="mt-3">
                    Vehicle Number: {invoiceData.vehicleNumber}
                  </p>
                  <p className="mt-3">
                    Vehicle Model: {invoiceData.vehicleModel}
                  </p>
                  <p className="mt-3">
                    Payment Type:{" "}
                    {invoiceData.paymentType?.toLowerCase() || paymentType}
                  </p>

                  {(paymentType === "credit" ||
                    invoiceData.paymentType === "CREDIT") && (
                    <>
                      <p className="mt-3">
                        Credit Status:
                        <span
                          className={`ms-2 d-inline-block p-1 rounded border credit-status ${
                            isSettled
                              ? "credit-status-settled"
                              : "credit-status-unsettled"
                          }`}
                        >
                          {currentStatus}
                        </span>
                        <button
                          className="btn btn-link"
                          onClick={handleChangeCreditStatus}
                        >
                          Change
                        </button>
                      </p>
                      <button
                        className="save-btn2"
                        onClick={updateCreditStatus}
                      >
                        Update
                      </button>
                    </>
                  )}

                  <button className="save-btn2" onClick={handleSaveAndPrint}>
                    Print Invoice
                  </button>
                </>
              )}
            </div>

            {!paymentType && !invoiceData.paymentType && (
              <>
                <p>
                  Average Kms of Vehicle: {invoiceData.vehicleAveragekmsPerday}{" "}
                  kms/day
                </p>
                <p className="mt-3">
                  Next {invoiceData.vehicleOdometerReading} kms on:{" "}
                  {upcominAlertDate}
                </p>
                <select
                  ref={paymentTypeRef}
                  className="w-50 form-select mt-3"
                  defaultValue=""
                >
                  <option value="">Select Payment Type</option>
                  <option value="cash">Cash</option>
                  <option value="credit">Credit</option>
                </select>
                <button
                  className="save-btn"
                  onClick={() => {
                    if (!paymentTypeRef.current.value) {
                      alert("Please select a payment type.");
                    } else {
                      handlePaymentTypeChange();
                    }
                  }}
                >
                  Save Invoice
                </button>
              </>
            )}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default Invoice;
