import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../core/loader/loader";
import DataTransferService from "../../core/service/dataTransferService";
import UserService from "../../core/service/userService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DefaultProfile from "../../assets/images/defaultProfile.png";
import SearchInput from "../../common/search";

const ViewBranches = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [branchProfile, setBranchProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const dataTransferService = new DataTransferService();
  const userService = new UserService();
  const navigate = useNavigate();
  const organisationDTO = dataTransferService.getOrganisationDTO();
  const branchDTO = dataTransferService.getBranchDTO();
  const userProfileUrl = dataTransferService.getUserProfile()
    ? `${dataTransferService.getBloburl()}${dataTransferService.getUserProfile()}`
    : DefaultProfile;

  useEffect(() => {
    fetchBranchesFromLocalStorage();
  }, []);

  const fetchBranchesFromLocalStorage = () => {
    setLoading(true);
    // Check if organisationDTO is valid and contains branchDTOList
    if (organisationDTO && organisationDTO.branchDTOList) {
      setLoading(false);
      setBranchesData(organisationDTO.branchDTOList);
      setBranchProfile(organisationDTO.organisationProfile);
      return;
    }

    if (branchDTO && branchDTO.branchDTOList) {
      setLoading(false);
      setBranchesData(branchDTO.branchDTOList);
      setBranchProfile(branchDTO.organisationProfile);
      return;
    }

    setLoading(false);
    console.warn("No organisationDTO or branchDTO found in localStorage");
    setBranchesData([]);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleProceed = () => {
    navigate("/create-branches");
  };

  const filteredBranches = branchesData.filter(
    (branch) =>
      branch.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      branch.branchAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // useEffect(() => {
  //   const fetchUserProfile = async () => {
  //     try {
  //       const userProfile = await dataTransferService.getOrgProfileImage();
  //       setProfile(userProfile || "");
  //     } catch (error) {
  //       console.error("Error fetching user profile:", error);
  //     }
  //   };
  //   fetchUserProfile();
  // }, []);

  const handleOrganisationCreation = () => {
    setLoading(true);
    // Check if organisationDTO exists and call the appropriate service
    // const serviceMethod = organisationDTO
    //   ? userService.createOrganisation
    //   : userService.createBranch;

    if (organisationDTO && organisationDTO.branchDTOList) {
      userService
        .createOrganisation(organisationDTO)
        .then((response) => {
          if (response.data.responseCode === 201) {
            setLoading(false);
            navigate("/invite-users");
            dataTransferService.removeBranchDTO();
          }
        })
        .catch((error) => {
          setLoading(false);
          handleErrorResponse("admin-create-organisation", error.response);
        });
    }

    // Check if branchDTO is valid and contains branchDTOList
    if (branchDTO && branchDTO.branchDTOList) {
      userService
        .createBranch(branchDTO)
        .then((response) => {
          if (response.data.responseCode === 201) {
            setLoading(false);
            navigate("/manage-branches/"+branchDTO.organisationName);
            dataTransferService.removeBranchDTO();
          }
        })
        .catch((error) => {
          setLoading(false);
          handleErrorResponse("admin-create-organisation", error.response);
        });
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center full-height">
      {loading && <Loader loading={loading} />}

      <div className="view-branches-container p-4 d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-22 fw-600">Hi {dataTransferService.getUserName()}</p>
          <img
            src={userProfileUrl}
            alt="user-profile-icon"
            className="rounded-circle me-3"
            width={42}
            height={42}
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <hr />
        <div className="pt-5 d-flex align-items-center justify-content-between">
          <p className="fs-4 fw-600">
            {dataTransferService.getOrgName() || "Organisation Name"}
          </p>
          <button
            type="button"
            className="btn btn-outline custom-btn-outline ms-4"
            onClick={handleProceed}
          >
            + Add location
          </button>
        </div>

        <div className="pt-40">
          <p className="pt-3 fs-6 fw-600">Your locations</p>
        </div>

        <div className="pt-4">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
          />
        </div>

        {filteredBranches.length > 0 ? (
          filteredBranches.map((branch, index) => (
            <div
              key={index}
              className="pt-32 d-flex align-items-center justify-content-center"
            >
              <img
                src={dataTransferService.getBloburl() + branchProfile}
                alt="organisation-logo"
                width={80}
                height={80}
              />
              <div className="d-flex flex-column pe-67 ps-4 w-100">
                <span className="fs-6 fw-500">{branch.branchName}</span>
                <span className="fs-15 fw-500">{branch.branchAddress}</span>
                <span className="fs-14 fw-light">
                  Ph: {branch.branchMobileNumber}
                </span>
              </div>
              <hr />
            </div>
          ))
        ) : (
          <p className="d-flex align-items-center justify-content-center mt-5 text-danger">
            No results found
          </p>
        )}

        <div className="mt-5 d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-success w-25"
            onClick={handleOrganisationCreation}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewBranches;
