import { React, useState, useEffect } from "react";
import { FaCheckCircle, FaTimesCircle, FaClock } from "react-icons/fa";
import { Table } from "react-bootstrap";
import Flex from "../../common/flex";
import { Link, useNavigate } from "react-router-dom";
import { DropdownComponent } from "../../common/dropDown";
import Profile from "../../common/profile";
import ReturnTrackerRequest from "../../core/service/returnTrackerRequest";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../../core/service/dataTransferService";
import Loader from "../../core/loader/loader";
import SearchInput from "../../common/search";
import ViewAll from "../../common/viewAll";
import SubtleBadge, {
  statusByColor,
  toPascalWord,
} from "../../common/subtleBadge";

const ReturnTrackerHome = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Newest");
  const returnTrackerRequest = new ReturnTrackerRequest();
  const dataTransferService = new DataTransferService();
  const [counts, setCounts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);
 
  const fetchReturnServices = () => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .fetchReturnCountsForBranch(branchName, organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          const { IN_PROCESS, PASSED_QC, FAILED_QC } =
            response.data.responseBody;
          setCounts({ IN_PROCESS, PASSED_QC, FAILED_QC });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchReturnServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };
  const fetchReturnRequests = (serialNumber, page, size) => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .searchReturnsBySerialNumber(
        organisationName,
        branchName,
        serialNumber,
        page,
        size
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchReturns", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };
 
  const fetchSortedReturns = (order, selectOptions, page, size) => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .sortReturnRequests(
        order,
        organisationName,
        branchName,
        page,
        size,
        selectOptions
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchSortedReturns", error.response);
        setErrorMessage(error.response.data.responseMessage);
        setTotalItems(0);
      });
  };
 
  useEffect(() => {
    if (searchTerm) {
      fetchReturnRequests(searchTerm, pageIndex, pageSize);
    } else {
      fetchReturnServices();
      fetchSortedReturns(selectedOption, selectOptions, pageIndex, pageSize);
    }
  }, [searchTerm, pageIndex, selectedOption]);
 
  const handleChangeOptions = (option) => {
    setSelectedOption(option);
    setPageIndex(0);
  };
 
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
 
  const clearSearch = () => {
    setSearchTerm("");
  };
 
  const navigate = useNavigate();
 
  const handlenavigate = () => {
    navigate("/new-return-tracker");
  };
 
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };
 
  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="count-container d-flex justify-content-evenly align-items-center mt-5">
        <div className="text-center d-flex  count-containers ml-100 mr-146">
          <div className="process-icon">
            <FaClock className="clock-icon fs-2" />
          </div>
          <div className="ms-3">
            <p className="count-head fs-14">In Process</p>
            <p className="fw-bold count-number-style">{counts.IN_PROCESS}</p>
          </div>
        </div>
 
        <div className="text-center d-flex count-containers mr-146">
          <div className="passed-icon">
            <FaCheckCircle className="tick-icon fs-2" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Passed QC</p>
            <p className="fw-bold count-number-style">{counts.PASSED_QC}</p>
          </div>
        </div>
 
        <div className="text-center d-flex count-containers mr-57">
          <div className="failed-icon">
            <FaTimesCircle className="fs-2 wrong-icon" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Failed QC</p>
            <p className="fw-bold count-number-style">{counts.FAILED_QC}</p>
          </div>
        </div>
      </div>

      <div className="card-all-return-requests p-4">
        <p className="fs-22 fw-600 ms-3 pt-8">All Return Requests</p>
        <Flex className="mt-3 mb-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
          />

          {!searchTerm && (
            <DropdownComponent
              value={selectedOption}
              selected={
                <Flex className="d-flex align-items-center">
                  <span className="fs-12 fw-400 grayish-blue pe-2">
                    Sort by:
                  </span>
                  {selectedOption}
                </Flex>
              }
              options={selectOptions}
              onSelect={handleChangeOptions}
              toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
            />
          )}
        </Flex>
        <Flex className="align-items-center justify-content-end ">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline mb-3"
            onClick={handlenavigate}
          >
            + Create a new Return Tracker
          </button>
        </Flex>
 
        <Table>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Company</th>
              <th>Model Name</th>
              <th>Customer name</th>
              <th>Created</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              returnRequests.map((request) => (
                <tr key={request.serialNumber}>
                  <td>{request.serialNumber}</td>
                  <td>{request.company}</td>
                  <td className="limited-text">{request.modelName}</td>
                  <td>
                    <Link
                      to={`/customer-return-trackers/${request.customerName}`}
                      style={{
                        color: "#127BFF",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      {request.customerName}
                    </Link>
                  </td>
                  <td>{formatDate(request.created)}</td>
                  <td>
                    <SubtleBadge
                      bg={statusByColor(request.returnStatus)}
                      className="fs-14 fw-500"
                    >
                      {toPascalWord(request.returnStatus)}
                    </SubtleBadge>
                  </td>
                  <td>
                    <Link
                      to={`/edit-return-tracker/${request.serialNumber}`}
                      style={{
                        color: "#127BFF",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <div className="mt-4">
          <ViewAll totalItems={totalItems} to="/view-all-return-requests" />
        </div>
      </div>
    </div>
  );
};
export default ReturnTrackerHome;