export default class DataTransferService {
  setUuid(token) {
    return localStorage.setItem("Uuid", token);
  }

  logout() {
    return localStorage.clear();
  }

  getUuid() {
    return localStorage.getItem("Uuid");
  }

  setOrgAndBranches(data) {
    return localStorage.setItem("orgAndBran", JSON.stringify(data));
  }

  getOrgAndBranches() {
    return localStorage.getItem("orgAndBran") || null;
  }

  setSelectedOrganization(org) {
    return localStorage.setItem("selectedOrg", org);
  }

  getSelectedOrganization() {
    return localStorage.getItem("selectedOrg");
  }

  setListOfBranches(bran) {
    return localStorage.setItem("listOfBranches", bran);
  }

  getListOfBranches() {
    return localStorage.getItem("listOfBranches");
  }

  setSelectedBranch(bran) {
    return localStorage.setItem("selectedBranch", bran);
  }

  getSelectedBranch() {
    return localStorage.getItem("selectedBranch");
  }

  setListOfOrg(org) {
    return localStorage.setItem("listOfOrg", org);
  }

  getListOfOrg() {
    return localStorage.getItem("listOfOrg");
  }

  setUserInfo(userEmail, userName, userRole, userProfile) {
    const userInfo = {
      userEmail: userEmail,
      userName: userName,
      userRole: userRole,
      userProfile: userProfile,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  getUserInfo() {
    const userInfo = localStorage.getItem("userInfo");
    return userInfo ? JSON.parse(userInfo) : null;
  }

  getBloburl() {
    return "https://retailersdev.blob.core.windows.net/images/";
  }

  setOrganisationDTO(organisationDTO) {
    return localStorage.setItem("organisationDTO", organisationDTO);
  }

  setBranchDTO(branchDTO) {
    return localStorage.setItem("branchDTO", branchDTO);
  }

  getOrgProfileImage() {
    const organisationDTOString = localStorage.getItem("organisationDTO");
    if (organisationDTOString) {
      const organisationDTO = JSON.parse(organisationDTOString);
      return organisationDTO.organisationProfile || null;
    }
    return null;
  }

  getOrgProfileImageFromBranchData() {
    const branchDTOString = localStorage.getItem("branchDTO");
    if (branchDTOString) {
      const branchDTO = JSON.parse(branchDTOString);
      return branchDTO.organisationProfile || null;
    }
    return null;
  }

  getOrgName() {
    const organisationDTOString = localStorage.getItem("organisationDTO");
    if (organisationDTOString) {
      const organisationDTO = JSON.parse(organisationDTOString);
      return organisationDTO.organisationName || null;
    }
    return null;
  }

  getOrgCount() {
    const organisationDTOString = localStorage.getItem("organisationDTO");
    if (organisationDTOString) {
      const organisationDTO = JSON.parse(organisationDTOString);
      return organisationDTO.numLocations || null;
    }
    return null;
  }

  getUserName() {
    const userInfoString = localStorage.getItem("userInfo");
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      return userInfo.userName || null;
    }
    return null;
  }

  getUserRole() {
    const userInfoString = localStorage.getItem("userInfo");
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      return userInfo.userRole || null;
    }
    return null;
  }

  getUserProfile() {
    const userInfoString = localStorage.getItem("userInfo");
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      return userInfo.userProfile || null;
    }
    return null;
  }

  removeOrganisationDTO() {
    localStorage.removeItem("organisationDTO");
  }

  removeBranchDTO() {
    localStorage.removeItem("branchDTO");
  }

  getOrganisationDTO() {
    const data = localStorage.getItem("organisationDTO");
    return data ? JSON.parse(data) : null;
  }

  getBranchDTO() {
    const data = localStorage.getItem("branchDTO");
    return data ? JSON.parse(data) : null;
  }

  getOrganisationProfile() {
    const orgAndBranString = this.getOrgAndBranches();
    if (orgAndBranString) {
      const orgAndBran = JSON.parse(orgAndBranString);
      const orgData = orgAndBran.find(
        (org) => org.organisationName === this.getSelectedOrganization()
      );

      return orgData ? orgData.organisationProfile : null;
    }

    return null;
  }
}
