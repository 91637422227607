import React, { useEffect, useState } from "react";
import Profile from "../../common/profile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserService from "../../core/service/userService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";
import DataTransferService from "../../core/service/dataTransferService";
import SearchInput from "../../common/search";
import { Back } from "../../common/back";

const ManageBranches = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userService = new UserService();
  const location = useLocation();
  const { organisationName } = useParams();
  const [loading, setLoading] = useState(true);
  const dataTransferService = new DataTransferService();

  useEffect(() => {
    if (organisationName) {
      fetchBranches(organisationName);
    }
  }, [organisationName]);

  const fetchBranches = (organisationName) => {
    setLoading(true);
    userService
      .fetchBranches(organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setBranchesData(response.data.responseBody);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("branches-by", error.response);
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const filteredBranches = branchesData.filter(
    (branch) =>
      branch.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      branch.branchAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const navigate = useNavigate();
  const navigateToCreateBranches = () => {
    if (location.pathname.startsWith("/manage-branches/")) {
      const storedData = dataTransferService.getBranchDTO();
      const organisationDTO = storedData
        ? JSON.parse(storedData)
        : { firstTime: true };
      organisationDTO.firstTime = false;
      organisationDTO.numLocations = filteredBranches.length;
      organisationDTO.organisationName = organisationName;
      organisationDTO.organisationProfile = branchesData[0].organisationProfile;
      organisationDTO.branchDTOList = [];
      dataTransferService.setBranchDTO(JSON.stringify(organisationDTO));
      navigate("/create-branches");
    }
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-between align-items-center pt-1">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>

      <div className="custom-container mt-36 p-4 ">
        <div className="d-flex align-items-center">
          <Back />
          <p className="fs-4 fw-600 ps-3 w-75 me-5">
            {organisationName || "Organisation Name"}
          </p>
          <button
            type="button"
            className="btn btn-outline custom-btn-outline ms-2"
            onClick={navigateToCreateBranches}
          >
            + Add location
          </button>
        </div>

        <div className="pt-4">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search by location, name"
          />
        </div>

        <div className="pt-40">
          <p className="pt-3 fs-6 fw-600">Your locations</p>
        </div>

        {filteredBranches.length > 0 ? (
          filteredBranches.map((branch) => (
            <div key={branch.branchName}>
              <div className="pt-32 d-flex align-items-center justify-content-center">
                <img
                  src={
                    dataTransferService.getBloburl() +
                    branch.organisationProfile
                  }
                  alt="user-profile-icon"
                  width={80}
                  height={80}
                  style={{
                    objectFit: "cover",
                  }}
                />
                <div className="d-flex flex-column pe-67 ps-4 w-100">
                  <span className="fs-6 fw-500">{branch.branchName}</span>
                  <span className="fs-15 fw-500">{branch.branchAddress}</span>
                  <span className="fs-14 fw-light">
                    Ph: {branch.branchMobileNumber}
                  </span>
                </div>
                <hr />
              </div>
              <hr />
            </div>
          ))
        ) : (
          <p className="d-flex align-items-center justify-content-center text-danger">
            No results found
          </p>
        )}
      </div>
    </div>
  );
};

export default ManageBranches;
