import { useState, useEffect } from "react";
import { DropdownComponent } from "../../common/dropDown";
import Profile from "../../common/profile";
import Loader from "../../core/loader/loader";
import { PaginationComponent } from "../../common/pagination";
import ServiceRequest from "../../core/service/serviceRequest";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { Link } from "react-router-dom";
import Flex from "../../common/flex";
import DataTransferService from "../../core/service/dataTransferService";
import SearchInput from "../../common/search";
import { Back } from "../../common/back";

const ServiceAlerts = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Newest");
  const [searchTerm, setSearchTerm] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const dataTransferService = new DataTransferService();
  const serviceRequest = new ServiceRequest();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();

  const fetchService = (page, size, order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .fetchServices(order, page, size, branchName, organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const searchService = (page, size) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .searchServices(page, size, branchName, organisationName, searchTerm)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("searchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      searchService(pageIndex, pageSize);
    } else {
      fetchService(pageIndex, pageSize, selectedOption);
    }
  }, [pageIndex, pageSize, selectedOption, searchTerm]);

  const handleChangeOptions = (option) => {
    setSelectedOption(option);
    setPageIndex(0);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const formatNumber = (num) =>
    num >= 1_000_000 ? (num / 1_000_000).toFixed(1) + "M" : 
    num >= 1_000 ? (num / 1_000).toFixed(1) + "K" : num;

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex align-items-center justify-content-end mt-15">
        <Profile />
      </Flex>

      <div className="mt-5 p-4 select-vehicle-container mt-5">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="fs-22 fw-600 ps-2">Service Alerts</p>
        </Flex>

        <Flex className="pt-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
          />

          {!searchTerm && (
            <DropdownComponent
              value={selectedOption}
              selected={
                <Flex className="d-flex align-items-center">
                  <span className="fs-12 fw-400 grayish-blue pe-2">
                    Sort by:
                  </span>
                  {selectedOption}
                </Flex>
              }
              options={selectOptions}
              onSelect={handleChangeOptions}
              toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
            />
          )}
        </Flex>

        <table className="table mt-5">
          <thead>
            <tr className="fs-14 fw-500">
              <th className="grayish-blue">Vehicle Number</th>
              <th className="grayish-blue">Category</th>
              <th className="grayish-blue">Mobile Number</th>
              <th className="grayish-blue">Payment Type</th>
              <th className="grayish-blue">Actions</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              servicesData.map((service) => (
                <tr key={service.invoiceData.invoiceSerialNumber}>
                  <td style={{ padding: "1rem" }}>
                    <Link
                      to={`/alerts-history/${service.invoiceData.vehicleNumber}`}
                      style={{ color: "#127BFF", textDecoration: "none" }}
                    >
                      {service.invoiceData.vehicleNumber}
                    </Link>
                  </td>
                  <td style={{ padding: "1rem" }}>{service.vehicleType}</td>
                  <td style={{ padding: "1rem" }}>
                    {service.invoiceData.customerMobileNumber}
                  </td>
                  <td style={{ padding: "1rem" }}>
                    {service.paymentType || "N/A"}
                  </td>
                  <td style={{ padding: "1rem" }}>
                    <Link
                      to={`/invoice/${service.invoiceData.invoiceSerialNumber}`}
                      style={{ color: "#127BFF", textDecoration: "none" }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
          <span>
            {totalItems > 0
              ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                  (pageIndex + 1) * pageSize,
                  totalItems
                )} of ${formatNumber(totalItems)} entries`
              : "No entries available"}
          </span>

          <PaginationComponent
            prevPage={prevPage}
            nextPage={nextPage}
            isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
            isPrevDisabled={pageIndex === 0}
            pageIndex={pageIndex}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </Flex>
      </div>
    </div>
  );
};

export default ServiceAlerts;
