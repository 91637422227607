import React, { useState } from "react";
import { Formik, Form } from "formik";
import Loader from "../../core/loader/loader";
import Flex from "../../common/flex";
import Profile from "../../common/profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import DataTransferService from "../../core/service/dataTransferService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { sendBulkAlertsValidations } from "../../validations/forms";
import { toast } from "react-toastify";
import UserService from "../../core/service/userService";
import { SelectField } from "../../validations/inputFields";

const SendBulkAlerts = () => {
  const messageContainerStyle = {
    backgroundColor: "#E5FFD8",
    borderRadius: "0.625rem",
    padding: "1rem",
    width: "24.5rem",
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const initialValues = {
    vehicleType: "",
    vehicleManufacturer: "",
    vehicleModel: "",
  };

  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const userService = new UserService();

  const fetchCount = (data) => {
    setLoadingPage(true);
    userService
      .fetchCountToSendBulkAlerts(
        organisationName,
        branchName,
        data.vehicleType,
        data.vehicleModel,
        data.vehicleManufacturer
      )
      .then((response) => {
        setLoadingPage(false);
        if (response?.data?.responseCode === 200) {
          setLoadingPage(false);
          const alertCount = response.data.responseBody;
          setAlertMessage(
            `${alertCount} Results Found For ${data.vehicleManufacturer} ${data.vehicleModel}`
          );
          toast.success("Bulk alerts count fetched successfully!");
        }
      })
      .catch((error) => {
        setLoadingPage(false);
        handleErrorResponse("fetchCountToSendBulkAlerts", error.response);
      });
  };

  const handleSearch = (values, { setSubmitting }) => {
    const formattedData = {
      vehicleType: values.vehicleType?.toUpperCase(),
      vehicleManufacturer: values.vehicleManufacturer?.toUpperCase(),
      vehicleModel: values.vehicleModel?.toUpperCase(),
    };
    fetchCount(formattedData);
    setSubmitting(false);
  };

  const handleSendAlerts = (values) => {
    setLoadingButton(true);
    const formattedData = {
      vehicleType: values.vehicleType?.toUpperCase(),
      vehicleManufacturer: values.vehicleManufacturer?.toUpperCase(),
      vehicleModel: values.vehicleModel?.toUpperCase(),
    };
    userService
      .sendBulkAlertsBasedOnVehicle(
        organisationName,
        branchName,
        formattedData.vehicleType,
        formattedData.vehicleModel,
        formattedData.vehicleManufacturer
      )

      .then((response) => {
        setLoadingButton(false);
        if (response.data.responseCode === 200) {
          setLoadingPage(false);
          toast.success("Reminder sent successfully!", { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoadingButton(false);
        handleErrorResponse("sendBulkAlerts", error.response);
      });
  };

  return (
    <div className="p-4">
      {loadingPage && <Loader loading={loadingPage} />}
      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>
      <div className="alerts-bg-container p-4">
        <div className="row">
          <div className="col-12">
            <p className="bulk-alerts-heading mb-5">Bulk Alerts</p>
            <div>
              <p className="fs-6 bulk-alerts-heading mb-3">
                Select the fields to send alerts
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={sendBulkAlertsValidations}
                onSubmit={(values, { setSubmitting }) => {
                  handleSearch(values, { setSubmitting });
                }}
              >
                {({ values }) => (
                  <Form>
                    <div className="row">
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Type"
                          id="vehicleType"
                          name="vehicleType"
                          options={["CAR", "TRUCK"]}
                          className="placeholder-text"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Manufacturer"
                          id="vehicleManufacturer"
                          name="vehicleManufacturer"
                          options={["TOYOTA", "HONDA", "FORD", "BMW", "AUDI"]}
                          className="placeholder-text"
                        />
                      </div>
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Model"
                          id="vehicleModel"
                          name="vehicleModel"
                          options={["COROLLA", "CIVIC", "MUSTANG"]}
                          className="placeholder-text"
                        />
                      </div>
                      <div className="col-2 d-flex justify-content-end button-search">
                        <button type="submit" className="button-indigo">
                          Search
                        </button>
                      </div>
                      <h6 className="strong-blue">{alertMessage}</h6>
                    </div>
                    <div className="mt-5">
                      <h6>Message Preview:</h6>
                      <div className="mt-4" style={messageContainerStyle}>
                        <h6>Greetings from {organisationName}</h6>
                        <h6 className="mt-4">Dear Customer,</h6>
                        <p className="fs-14 fw-500">
                          We’re reaching out from {organisationName}. with
                          an update on items suited for your CAR Model: COROLLA,
                          Manufacturer: TOYOTA. We have limited stock available
                          for these items, so please visit us at your
                          convenience if you’d like to explore your options.
                        </p>
                        <h6 className="mt-4">Best regards,</h6>
                        <h6>{organisationName}</h6>
                      </div>
                    </div>
                    <div className="pt-4">
                      <button
                        type="button"
                        className="p-2 button-styling w-25"
                        disabled={loadingButton}
                        onClick={() => {
                          if (
                            !values.vehicleType ||
                            !values.vehicleManufacturer ||
                            !values.vehicleModel
                          ) {
                            toast.error(
                              "Please select the filtration fields to send a reminder!",
                              {
                                theme: "colored",
                              }
                            );
                          } else {
                            handleSendAlerts(values);
                          }
                        }}
                      >
                        {!loadingButton && (
                          <FontAwesomeIcon
                            icon={faPaperPlane}
                            className="me-3 rotate-up"
                          />
                        )}
                        {loadingButton ? (
                          <Loader loading={loadingButton} />
                        ) : (
                          "Send Reminder Now"
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendBulkAlerts;
