/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const loaderStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; /* Ensure the loader is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
`;

const Loader = ({ loading }) => {
  return (
    loading && (
      <div css={loaderStyles} role="status" aria-live="polite" aria-hidden={!loading}>
        <ClipLoader color={"#5932EA"} loading={loading} size={50} />
      </div>
    )
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
