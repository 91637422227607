import React from "react";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from "prop-types";
import { cssTransition } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/scss/toastify.scss";
library.add(faTimes);
export const Fade = cssTransition({ enter: "fadeIn", exit: "fadeOut" });

export const CloseButton = ({ closeToast }) => (
  <FontAwesomeIcon
    icon="times"
    className="my-2 fs-11"
    style={{ opacity: 0.5 }}
    onClick={closeToast}
  />
);

CloseButton.propTypes = { closeToast: PropTypes.func };