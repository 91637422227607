import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faFrownOpen,
  faHome,
  faThunderstorm,
} from "@fortawesome/free-solid-svg-icons";
import DataTransferService from "../core/service/dataTransferService";
import { OrgChangeDropDown } from "../common/dropDown";
import "bootstrap/dist/css/bootstrap.min.css";
import Flex from "../common/flex";
import Loader from "../core/loader/loader";

const SideNav = () => {
  const dataTransferService = useMemo(() => new DataTransferService(), []);
  const [orgData, setOrgData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [branches, setBranches] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [allBranchesReport, setAllBranchesReport] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataTransferService.getSelectedBranch() !== "All Branches Report") {
      setAllBranchesReport(false);
    }
    const storedOrg = dataTransferService.getSelectedOrganization();
    const storedData = dataTransferService.getOrgAndBranches();
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setOrgData(parsedData);
      const orgNamesString = parsedData
        .map((org) => org.organisationName)
        .join(", ");
      dataTransferService.setListOfOrg(orgNamesString);
      if (storedOrg) {
        const matchedOrg = parsedData.find(
          (org) => org.organisationName === storedOrg
        );
        if (matchedOrg) {
          setSelectedOrg(matchedOrg.organisationName);
          setBranches(matchedOrg.branches);
          dataTransferService.setSelectedOrganization(
            matchedOrg.organisationName
          );
          dataTransferService.setListOfBranches(matchedOrg.branches);
        }
      } else if (parsedData.length > 0) {
        setSelectedOrg(parsedData[0].organisationName);
        setBranches(parsedData[0].branches);
        dataTransferService.setSelectedOrganization(
          parsedData[0].organisationName
        );
        dataTransferService.setListOfBranches(parsedData[0].branches);
      }
    }
  }, [dataTransferService]);

  const handleSelectOrg = (selectedValue) => {
    setLoading(true);
    const org = orgData.find((o) => o.organisationName === selectedValue);
    if (org) {
      setDropdownOpen(false);
      setSelectedOrg(org.organisationName);
      setBranches(org.branches);
      dataTransferService.setSelectedOrganization(org.organisationName);
      dataTransferService.setListOfBranches(org.branches);
      const branList = dataTransferService.getListOfBranches();
      if (branList) {
        const parsedBranches = branList.split(",");
        setBranches(parsedBranches);
        if (parsedBranches.length > 0) {
          const initialBranch = parsedBranches[0].trim();
          dataTransferService.setSelectedBranch(initialBranch);
          const currentPath = window.location.pathname;
          window.location.href = currentPath;
        }
      }
    }
  };

  const orgOptions = orgData.map((org, index) => ({
    id: index,
    value: org.organisationName,
    label: org.organisationName,
  }));

  const handleProceed = () => {
    navigate("/");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="p-3">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex align-items-center justify-content-between mt-4 mx-19">
        <div className="org-img">
          <button
            type="button"
            className="btn-no-style"
            onClick={handleProceed}
            aria-label="Proceed to profile"
          >
            <img
              src={
                dataTransferService.getBloburl() +
                dataTransferService.getOrganisationProfile()
              }
              alt="User profile"
              width={42}
              height={42}
              style={{
                objectFit: "cover",
              }}
            />
          </button>
        </div>

        <div className="d-flex flex-column">
          <OrgChangeDropDown
            selected={selectedOrg}
            options={orgOptions}
            onSelect={handleSelectOrg}
            toggleClassName="fs-14 fw-500 OrgChangeDropDown custom-arrow"
            isOpen={isDropdownOpen}
            onToggle={toggleDropdown}
          />
          <span className="fs-12 fw-normal grayish-blue">
            {dataTransferService.getSelectedBranch()}
          </span>
        </div>

        <div onClick={toggleDropdown} className="pointer">
          <FontAwesomeIcon icon={faAngleDown} className="dim-gray" />
        </div>
      </Flex>

      <div className="mt-5 mx-1 px-2">
        {!allBranchesReport && (
          <nav className="navbar">
            <ul>
              {/* Billing Section */}
              <li>
                <NavLink
                  to="/"
                  className="nav-link ctive pointer px-3 d-flex align-items-center h-46"
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    className="pointer pe-14 pe-16 fs-14 fw-500"
                  />
                  Billing
                </NavLink>

                <div className="px-4">
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/service-alerts" className="sub-nav-link">
                        Service Alerts
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/recent-services" className="sub-nav-link">
                        Billing History
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Return Tracker Section */}
              <li>
                <NavLink
                  to="/return-tracker"
                  className="nav-link ctive pointer px-3 d-flex align-items-center h-46"
                >
                  <FontAwesomeIcon
                    icon={faFrownOpen}
                    className="pointer pe-14 pe-16 fs-14 fw-500"
                  />
                  Return Tracker
                </NavLink>

                <div className="px-4">
                  <ul className="sub-menu">
                    <li>
                      <NavLink
                        to="/new-return-tracker"
                        className="sub-nav-link"
                      >
                        Create new Return
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/view-all-return-requests"
                        className="sub-nav-link"
                      >
                        Returns History
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Service Alerts Section */}
              <li>
                <NavLink
                  to="/vehicle-billing"
                  className="nav-link ctive pointer px-3 d-flex align-items-center h-46"
                >
                  <FontAwesomeIcon
                    icon={faThunderstorm}
                    className="pointer pe-14 pe-16 fs-14 fw-500"
                  />
                  Vehicle Data
                </NavLink>

                <div className="px-4">
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/promotion-alerts" className="sub-nav-link">
                        Promotion Alerts
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default SideNav;
